<template>
  <div class="bg">
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <!-- Login Form -->
        <p v-if="incorrectAuth">{{ this.incorrectAuthmessage }}</p>
        <p>
          <img src="/static/media/miandum-logo.png" height="30" class="d-inline-block" alt />
        </p>
        <div v-if="changing_server">
          <form v-on:keyup.enter="change_server">
            <el-input v-model="server_url" placeholder="Enter server address" clearable />
            <el-button type="primary" plain @click="changing_server = false">Cancel</el-button>
            <el-button type="primary" plain @click="change_server">Set</el-button>
          </form>
        </div>
        <div v-else>
          <form v-on:keyup.enter="login">
            <el-input v-model="username" placeholder="Please input username" clearable />
            <el-input v-model="password" placeholder="Please input password" show-password />
            <el-button type="info" plain @click="changing_server = true" size="small">Change server</el-button>
            <el-button type="primary" plain @click="login">Log In</el-button>
          </form>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import { messages } from "@/popups";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
import { useI18n } from 'vue3-i18n'
export default {
  name: "Login",
  components: {},
  setup(){
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const I18n = useI18n()
    let username = ref("")
    let password = ref("")
    let server_url = ref(store.state.auth.host)
    let changing_server = ref(false)
    let incorrectAuth = ref(false)
    let incorrectAuthmessage = ref("")
    const {ws_open} = inject("ws_connection")
    
    const change_server = () => {
      // alert(server_url.value)
      store.commit("auth/set_host",server_url.value)
      store.dispatch("auth/set_root_host")
      changing_server.value = false
    }
  
    const login = () => {
        if (username.value == "" || password.value == "") {
          incorrectAuthmessage.value = "Username and password must be provided!";
          incorrectAuth.value = true;
        } else {
          
          incorrectAuthmessage.value = "";
          incorrectAuth.value = false;
          store
            .dispatch("auth/userLogin", {
              username: username.value,
              password: password.value,
            })
            .then(() => {
              I18n.setLocale(store.state.ui.locale);
              ws_open()
              router.push(route.query.redirect || "/");
            })
            .catch((err) => {
              const { showerorr } = messages()
              showerorr(err);
              incorrectAuth.value = true;
            });
        }
    }
    

    return{
      username,password,incorrectAuth,incorrectAuthmessage,login,changing_server,change_server,server_url
    }
  },
};
</script>

<style scoped>
/* STRUCTURE */
.bg {
    background-image: url("/static/media/bg.jpg");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  min-height: 100%;
  padding: 20px;
  opacity: 0.9;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #f6f2ef;
  padding: 20px;
  width: 95%;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}
#formContent .el-input {
  margin-top: 5px;
  margin-bottom: 5px;
}
#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}
</style>