<template>
  <div id="grid">
    <el-table
      :data="data"
      border
      header-row-class-name="grid_header"
      style="width: 100%"
      max-height="380"
    >
      <el-table-column class-name="actions" fixed="left" width="32" v-if="!store.state.auth.read_only">
        <template #default="scope">
          <el-icon
            @click="store.commit('api/delete_gridline', { view: props.vmodel, grid: props.meta.f, row: scope.$index + tablestart })"
          >
            <Delete />
          </el-icon>
        </template>
      </el-table-column>
      <el-table-column
        class-name="index"
        fixed="left"
        type="index"
        width="40"
        :index="indexCounter"
      />

      <el-table-column
        v-for="col in props.meta.c"
        :label="col.text || col.f"
        :key="col.f"
        :prop="col.f"
        :min-width="col.w"
      >
        <template #default="scope">
          <div v-if="col.t == 'CharField' || col.t == 'ForeignKey'" style="height: 100%;">
            <div v-if="col.m !== null && col.m" style="height: 100%;">
              <input
                :value="scope.row[col.f]"
                class="input_affix"
                @change="after_edit(scope.$index + tablestart, col.f, $event.target.value)"
                @keyup.f2="open_record($event,col.m)"
                :disabled="is_disabled(col)"
              />
              <el-icon v-if="!is_disabled(col)"
                class="el-input__icon"
                @click="openSelect(col.m, col.f,col.ro, scope.$index + tablestart)"
                style="position: absolute;right: 0; z-index: 99;top: 0; color: rgb(192, 196, 204);"
              >
                <Search />
              </el-icon>
            </div>
            <div v-else style="height: 100%;">
              <input
                :value="scope.row[col.f]"
                @change="after_edit(scope.$index + tablestart, col.f, $event.target.value)"
                :disabled="is_disabled(col)"
              />
            </div>
          </div>
          <div v-else-if="col.t == 'BooleanField'">
            <el-checkbox
              v-model="scope.row[col.f]"
              @change="after_edit(scope.$index + tablestart, col.f, $event)"
              size="medium"
              :disabled="is_disabled(col)"
            ></el-checkbox>
          </div>
          <div v-else-if="col.t == 'DropdownInteger'">
            <el-select :disabled="is_disabled(col)" v-model="scope.row[col.f]"
              @change="after_edit(scope.$index + tablestart, col.f, $event)">
              
              <el-option
                v-for="item in menus[col.menu]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div v-else-if="col.t == 'DateField'">
            <el-date-picker
              v-model="scope.row[col.f]"
              type="date"
              value-format="YYYY-MM-DD"
              format="DD.MM.YYYY"
              :clearable="clearable"
              :disabled="is_disabled(col)"
              @change="after_edit(scope.$index + tablestart, col.f, $event.target.value)"
            ></el-date-picker>
          </div>
          <div v-else-if="col.t == 'DecimalField'" style="height: 100%;">
            <input
              type="number"
              :value="scope.row[col.f]"
              :disabled="is_disabled(col)"
              @change="after_edit(scope.$index + tablestart, col.f, $event.target.valueAsNumber)"
            />
          </div>
          <div v-else-if="col.t == 'IntegerField'" style="height: 100%;">
            <input
              type="number"
              :value="scope.row[col.f]"
              :disabled="is_disabled(col)"
              @change="after_edit(scope.$index + tablestart, col.f, $event.target.valueAsNumber)"
            />
          </div>
          <div v-else style="height: 100%;">
            <input
              :value="scope.row[col.f]"
              :disabled="is_disabled(col)"
              @change="after_edit(scope.$index + tablestart, col.f, $event.target.value)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="!store.state.auth.read_only" style="margin-left: 10px;cursor: pointer; width: 30px; float: left; margin-top:5px;">
      <el-icon @click="addRow">
        <Plus />
      </el-icon>
    </div>

    <el-pagination
      :pager-count="9"
      :page-size="perPage"
      :total="total_records"
      @current-change="current_page"
      layout="prev, pager, next"
      :small="true"
      :hide-on-single-page="true"
      background
      style="margin-left: 10px;width: auto; float: right; margin-top:5px;"
    ></el-pagination>
  </div>
</template>


<script>
import { messages } from '@/popups';
import { Delete, Plus, Search } from "@element-plus/icons";
import { computed, inject, ref } from "vue";
import { useRouter } from "vue-router";
import { apiHelpers } from "../helpers";
import { uiHelpers } from "../ui_helpers";
export default {
  name: "ModelDetails",
  components: {
    Search, Delete, Plus
  },
  props: {
    meta: Object,
    vmodel: String
  },
  setup(props) {
    const { store } = apiHelpers(props);
    const router = useRouter();
    const { menus } = uiHelpers(props);
    const select_model = inject("select_model");
    const current_detail_store = inject("current_detail_store");
    const currentfield = inject("currentfield");
    const currentrow = inject("currentrow");
    const currentgrid = inject("currentgrid");
    const lookupvisible = inject("lookupvisible");
    const currentPage = ref(1)
    const perPage = ref(10)
    const { showerorr } = messages()
    const addRow = () => {
      store.commit("api/add_row", {vmodel: props.vmodel,grid: props.meta.f})
    };
    const openSelect = async (m, f,ro, r) => {
      if (ro !== 'undefined' && ro !== null && ro){
        return
      }
      select_model.value = m;
      currentfield.value = f;
      current_detail_store.value = props.vmodel
      currentrow.value = r;
      currentgrid.value = props.meta.f
      lookupvisible.value = !lookupvisible.value;
    };
    // const data = computed(() => store.state.api[props.vmodel][props.meta.f])
    const tablestart = computed(() => (currentPage.value - 1) * perPage.value)
    const total_records = computed(() => store.state.api[props.vmodel][props.meta.f].length)
    const data = computed(() => store.state.api[props.vmodel][props.meta.f].slice(tablestart.value, tablestart.value + perPage.value))
    const current_page = async (current) => {
      currentPage.value = current
    }
    const indexCounter = (index) => {
      return index + tablestart.value + 1
    }


    const after_edit = (row, field, value) => {
      console.log(typeof(value))
      store.dispatch('api/after_edit', { grid: props.meta.f, row: row, field: field, value: value, view: props.vmodel })
        .then(() => { })
        .catch(err => {
          showerorr(err)
        })
    }

    const open_record = (e,model) => {
      if (e.target.value > ''){
        let routeData = router.resolve({name: 'popup', params: {model: model,key: e.target.value}});
        window.open(routeData.href,'popup'+model+e.target.value, 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1100,height=700');
      }
      
    } 
    const is_disabled = (col) => {
      return store.state.auth.read_only || (col.ro !== 'undefined' && col.ro !== null && col.ro)
    };

    const controls = ref(false)
    const clearable = ref(false)
    const prefix_icon = ref(null)
    const min = ref(null)
    return {
      data,
      props,
      openSelect,
      store,
      addRow,
      controls,
      clearable,
      prefix_icon,
      min,
      perPage,
      total_records,
      current_page,
      tablestart,
      indexCounter,
      after_edit,
      open_record,
      menus,
      is_disabled
    };
  },
};
</script>

<style>
#grid .el-table__cell {
  padding: 0 !important;
  margin: 0 !important;
  height: 32px;
}
#grid .cell {
  padding: 0 !important;
  margin: 0 !important;
  height: 32px;
  font-size: 0.9em;
}
#grid .el-input {
  padding: 0 !important;
  margin: 0 !important;
  height: 32px;
}
#grid input {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  border: none;
  border-radius: 0;
  --webkit-border-radius: 0;
  color: #606266;
  background-color: inherit;
  font-size: 12px !important;

}
#grid input:disabled {
  background-color:#f5f7fa;
  color: rgb(128, 128, 128);
}


.cell input.input_affix {
  padding: 0 25px 0 5px !important;
}

.cell input:not(.input_affix) {
  padding: 0 5px 0 5px !important;
}

.cell .el-input--prefix input {
  padding: 0 5px 0 30px !important;
}

#grid .el-checkbox__inner {
  margin: 0 10px !important;
}
#grid {
  padding: 0 5px;
}
#grid .grid_header .is-leaf {
  padding: 5px;
  background-color: #fbfbfb;
}

#grid .grid_header .is-leaf .cell {
  padding: 4px !important;
}
#grid .actions .cell,
#grid .index .cell {
  padding: 3px 8px !important;
  background-color: #fbfbfb;
}
#grid .actions,
#grid .index {
  background-color: #fbfbfb;
}
#grid .actions {
  cursor: pointer;
}

</style>
