<template>
  <div class="el-tab-pane">
    <NavigationIcon v-for="link in links" :text="link.text" :url="link.url" :key="link.url" />
  </div>
</template>


<script>
import NavigationIcon from "../NavigationIcon.vue";
// import { ref, onMounted } from 'vue'
// import { useStore } from "vuex";
export default {
  components: {
    NavigationIcon
  },
  props: {
    links: Array
  },
};
</script>

<style>
.el-table .cell {
  word-break: keep-all !important;
}
.el-tab-pane a:not(:last-child) {
  margin-right: 10px;
  float: left;
}

.el-tab-pane a:last-child {
  margin-right: 25px;
  float: left;
}
</style>
