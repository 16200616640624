import { messages } from '@/popups';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from "vuex";


export const apiHelpers = (model) => {
    const route = useRoute()
    const store = useStore();
    const list = computed(() => store.state.api.list);
    const details = computed(() => store.state.api.record_details);
    const lookup_data = computed(() => store.state.api.lookup_data);
    const { showerorr} = messages()
    const getlookuplist = async (model,page,filters) => {
        store
            .dispatch("api/get", { url: store.state.ui.lookup.root_url, model : model, type: 'lookup_list',q: Object.assign({page: page,page_size:50,},filters) })
            .catch((err) => {
                showerorr(err);
            });
    }

    const getrecordlist = async (page,filters) => {
        store
            .dispatch("api/get", { url: store.state.api.root_url, type: 'list',q: Object.assign({page: page,page_size:50,},filters)})
            .catch((err) => {
                showerorr(err);
            });
    }

    const getfilelist = async (key) => {
        store
            .dispatch("api/get", { url: "/common/media/", type: 'filelist', q:{model:model,code:key} })
            .then(()=> {})
            .catch((err) => {
                showerorr(err);
            });
    }

    
    const getrecordhistory = async (key) => {
        store.commit("api/freeze")
        if (key == undefined || key == '') {
            return
        }
        let url = ''
        if (store.state.ui.is_singleton){
            url = store.state.api.root_url + "/get_history"
        }  
        else{
            url = store.state.api.root_url + key + "/get_history"
        }
        store.dispatch("api/get", { url: url ,module: module, model: model, type: 'history' })
            .then(async ()=>{
                store.commit("api/unfreeze")
            })
            .catch((err) => {
                showerorr(err);
                store.commit("api/unfreeze")
            });
    }

    const getrecorddetails = async (key) => {
        store.commit("api/freeze")
        if (key == undefined || key == '') {
            return
        }
        let url = ''
        if (store.state.ui.is_singleton){
            url = store.state.api.root_url
        }  
        else{
            url = store.state.api.root_url + key + "/"
        }
            
        store
            .dispatch("api/get", { url: url ,module: module, model: model, type: 'details'})
            .then(async ()=>{
                await getfilelist(key)
                store.commit("api/unfreeze")
            })
            .catch((err) => {
                showerorr(err);
                store.commit("api/unfreeze")
            });
    }

    const get_prev = async (key) => {
        if (key == undefined || key == '') {
            return
        }
        store
            .dispatch("api/get", { url: store.state.api.root_url + key + "/", q : {}})
            .then(async (new_key)=>{
                return new_key
            })
            .catch((err) => {
                showerorr(err);
            });
    }

    
    return {
        getrecordlist,
        getrecorddetails,
        getrecordhistory,
        store,
        route,
        details,
        list,
        lookup_data,
        getlookuplist,
        getfilelist,
        get_prev
    }
};
