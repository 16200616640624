<template>
  <router-link v-bind:class="aclass" :to="{ name: 'modules', params: { module: module } }">
    <el-menu-item :index="index">{{ text }}</el-menu-item>
  </router-link>
</template>

<script>
export default {
  name: "NavbarLink",
  props: {
    text: String,
    model: String,
    path: String,
    module: String,
    index: String,
    aclass: String,
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
