<template>
  <el-affix :offset="0" id="record_list_container">
    <div style="height: 39px; border-bottom: 1px solid #d3d3d3;">
      <el-pagination :pager-count="5" :page-size="pagesize" :total="total_records" @current-change="current_page"
      layout="prev, pager, next" background
      style="margin-left: 10px;width: auto; float: right; margin-top:5px;"></el-pagination>
    </div>
    
    <el-table ref="singleTable" :height="calc_height" table-layout="auto" :data="list" size="mini"
      @row-click="handleCurrentChange" highlight-current-row  style="width: 100%" :row-class-name="tableRowClassName">
      <el-table-column v-for="col in meta.cols" :key="col.f" :prop="col.f" show-overflow-tooltip>
        <template #header>
          <input class="list_header" v-model="search[col.f]" v-on:keyup.enter="filter_list"
            :placeholder="translation(col.t)" />
        </template>
        <template v-if="col.tp == 'BooleanField'" #default="scope">
          <img v-if="scope.row[col.f]" src="/static/media/checked.png" />
          <img v-else src="/static/media/unchecked.png" />
        </template>
      </el-table-column>
    </el-table>
  </el-affix>
</template>


<script setup>
import { useRouter } from "vue-router";
import { defineProps, ref, onMounted, onUnmounted, computed } from "vue";
import { apiHelpers } from "../helpers";
import { uiHelpers } from "../ui_helpers";
const router = useRouter();
const props = defineProps({
  model: String,
});
let { list, getrecordlist, store } = apiHelpers(props.model);
let { meta, translation } = uiHelpers(props.model);
let selected = ref(null);
let calc_height = ref(window.innerHeight - 101);
// let list_width = ref(200);
let resize_handler = (e) => {
  calc_height.value = e.target.innerHeight - 101;
  // list_width.value = this.clientWidth
};
const search = ref({})
const singleTable = ref(null);
onMounted(() => {
  window.addEventListener("resize", resize_handler);
});
onUnmounted(() => {
  window.removeEventListener("resize", resize_handler);
});
async function handleCurrentChange(row) {
  // console.log(meta.value.keys)
  const keys = Array.from(meta.value.keys, (x) => row[x]);
  // console.log(keys)
  router
    .push({ params: { key: keys.join("~") } })
    .then(() => {
      selected.value = row;
    })
    .catch(() => {
      singleTable.value.setCurrentRow(selected.value);
    });
}

const pagesize = computed(() => 50)
const total_records = computed(() => store.state.api.list_count)

const current_page = async (current) => {
  getrecordlist(current, search.value)
}

async function filter_list() {
  getrecordlist(1, search.value)
}

const tableRowClassName = ({ row, }) => {
      if (row.display_status == undefined) {
        return ''
      }
      else if (row.display_status == "w") {
        return 'warning-row'
      } else if (row.display_status == "s") {
        return 'success-row'
      } else if (row.display_status == "e") {
        return 'error-row'
      } else if (row.display_status == "p") {
        return 'highlite-row'
      } else if (row.display_status == "x") {
        return 'extra_warning-row'
      }
      return ''
    }

// const next_page = () => {
//   console.log("next_page")
// }
</script>

<style scoped>
.el-table .cell {
  word-break: keep-all !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.list_header {
  height: 20px;
}

#record_list_container >>> .el-table th.el-table__cell>.cell {
  padding: 0;
  padding-left: 3px;
}

#record_list_container >>> .el-table th.el-table__cell>.cell input {
  border: none;
  border-right: 1px;
  padding-left: 10x;
}


.el-table .cell {
  word-break: keep-all !important;
}
.el-table >>> .warning-row {
  background-color: #ddccb2;
}
.el-table >>> .success-row {
  background-color: #acc59f;
}
.el-table >>> .error-row {
  background-color: #ffdcdc;
}
.el-table >>> .highlite-row {
  background-color: #daebfc;
}
.el-table >>> .extra_warning-row {
  background-color: #fd8181;
}
.el-table .current-row {
  font-weight: bold;
}

#record_list_container >>> .el-pagination.is-background .btn-next, 
#record_list_container >>> .el-pagination.is-background .btn-prev, 
#record_list_container >>> .el-pagination.is-background .el-pager li {
    margin: 0 2px !important;
    -webkit-box-shadow: 0px 0px 11px -5px #000000; 
    box-shadow: 0px 0px 9px -5px #000000;
}


</style>
