<template>
  <div id="log"></div>
</template>

<script>
import { inject } from 'vue'
export default {
  name: "Logout",
  setup(){
    const {ws_close} = inject("ws_connection")
    ws_close(3000)
  },
  created() {
    this.$store.dispatch("auth/userLogout").then(() => {
      this.$router.push({ name: "login" });
    });
  },
};
</script>

<style>
</style>