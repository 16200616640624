<template>
  <router-link :to="{ path: link_to }" style="text-decoration: none; color: inherit">
    <!-- <el-badge :value="12" :max="99" class="item"> -->
    <el-card shadow="hover">
      <el-row>
        <el-col>
          <el-icon v-if="type== 1" style="width: fit-content;">
            <List style="width: 2em; height: 2em;" />
          </el-icon>
          <el-icon v-else-if="type== 3" style="width: fit-content;">
            <Operation style="width: 2em; height: 2em;" />
          </el-icon>
          <el-icon v-else-if="type== 2" style="width: fit-content;">
            <Setting style="width: 2em; height: 2em;" />
          </el-icon>
          <el-icon v-else-if="type== 9" style="width: fit-content;">
            <Edit style="width: 2em; height: 2em;" />
          </el-icon>
          <el-icon v-else-if="type== 6" style="width: fit-content;">
            <PieChart style="width: 2em; height: 2em;" />
          </el-icon>
          <el-icon v-else style="width: fit-content;">
            <Document style="width: 2em; height: 2em;" />
          </el-icon>
        </el-col>
      </el-row>
      <el-row style="text-align: center; font-size: 0.7rem;padding: 0 5px;">
        <el-col>
          <b>{{ text }}</b>
        </el-col>
      </el-row>
    </el-card>
    <!-- </el-badge> -->
  </router-link>
</template>

<script>
import { Document, Setting, Edit, Operation, List, PieChart } from '@element-plus/icons'
import { computed } from 'vue'
export default {
  name: "NavigationIcon",
  components: {
    Document, Setting, Edit, Operation, List, PieChart
  },
  setup(props) {
    const link_to = computed(() => {
      if (props.type == 1) {
        return props.url
      }
      else if (props.type == 9) {
        return "/misc" + props.url
      }
      else if (props.type == 3) {
        return props.url
      }
      else if (props.type == 2) {
        return "/functions" + props.url
      }
      else if (props.type == 3) {
        return "/reports" + props.url
      }
      else if (props.type == 4) {
        return "/imports" + props.url
      }
      else if (props.type == 5) {
        return "/exports" + props.url
      }
      else if (props.type == 6) {
        return "/r/" + props.url
      }
      return ""
    })
    return { link_to }
  },
  props: {
    url: String,
    text: String,
    icon: String,
    type: Number
  },
  data: () => ({}),
  created() { },
};
</script>

<style scoped>
.el-card {
  /* margin: 0 0 30px 10px; */
  width: 100px;
  height: 100px;
  background-color: #8eb4d129  !important;
}
.el-card:hover {
  background-color: #5291c229 !important;
}

.el-card .el-row:not(:last-child) {
  margin-bottom: 10px;
}
.el-card >>> .el-card__body {
  width: 100%;
  padding: 10px 0 !important;
  text-align: center;
}
.el-badge {
  margin-top: 10px;
}

.el-tab-pane a:not(:last-child) {
  margin-right: 4px;
  float: left;
  margin-bottom: 4px;
}

.el-tab-pane a:last-child {
  margin-right: 4px;
  margin-bottom: 4px;
  float: left;
}
</style>
