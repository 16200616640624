


const ui = {
    namespaced: true,
    state() {
        return {
            modules: [],
            record_model:{},
            functions:[],
            cols: [],
            type:null,
            txt: {},
            field_translations: {},
            keys: [],
            actions: [],
            view: {},
            view_dialog: {},
            is_singleton: null,
            locale : null,
            widgets : {},
            menus : {},
            lookup: {
                cols: [],
                keys: [],
            }
        }
    },
    mutations: {
        widget_init(state, payload) {
            if (state.widgets[payload.widget] == undefined) {
                state.widgets[payload.widget] = {
                    str : "",
                    sort: {},
                    selected: null
                }
            } else{
                if (payload.str !== undefined){
                    state.widgets[payload.widget]["str"] = payload.str
                }
                if (payload.sort !== undefined){
                    state.widgets[payload.widget]["sort"] = payload.sort
                }
            }

            
        },
        widget_row_select(state, payload) {
            if (state.widgets[payload.widget] == undefined) {
                state.widgets[payload.widget] = {
                    str : "",
                    sort: {},
                    selected: payload.row
                }
            }
            if (payload.row !== undefined){
                state.widgets[payload.widget]["selected"] = payload.row
            }
        },
        meta(state, payload) {
            state.cols = payload.data.cols
            state.type = payload.data.type
            state.actions = payload.data.actions
            state.view = payload.data.view
            state.root_url = payload.data.root_url
            state.model = payload.data.model
            state.record_model = payload.data.datamodel
            state.keys = payload.data.keys
            state.txt = payload.data.translations
            state.is_singleton = payload.data.is_singleton
            state.menus = payload.data.menus
            state.field_translations = payload.data.field_translations
            this.commit("api/meta",{
                root_url: payload.data.root_url,
                datamodel: payload.data.datamodel,
                keys: payload.data.keys,
                model: payload.data.model,
                is_singleton: payload.data.is_singleton
            })
        },
        meta_dialog(state, payload) {
            state.view_dialog = payload.data.view
            this.commit("api/meta_dialog",{
                datamodel: payload.data.datamodel,
            })
        },
        clear_dialog(state) {
            state.dialog_details = {}
            state.view_dialog = {}
        },
        lookup_meta(state, payload) {
            state.lookup = payload.data
        },
        modules(state, payload) {
            state.modules = payload.data.results
        },
        add_menus(state, payload) {
            // console.log(payload)
            state.menus = Object.assign(state.menus,payload)
        },
        functions(state, payload) {
            state.functions = payload.data.results
        },
        reset(state){
            state.view= {},
            state.keys= [],
            state.actions= [],
            state.cols= [],
            state.view= {}
        },
        set_locale(state, payload) {
            state.locale = payload.locale
        },
    },
    getters: {
        actions(state) {
            return state.actions
        },
        view(state){
            return state.view
        },
        view_dialog(state){
            return state.view_dialog
        } 
        
    },
    actions: {
        async get({ commit }, params) {
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.get(params.url,{ params:params.q})
                    .then(response => {
                        if (params.type){
                            commit(params.type,
                                {
                                    data: response.data,
                                    status: "R"
                                })
                            resolve()
                        } else {
                            resolve(response.data)
                        }
                        
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        async get_locale({ commit, state }) {
            // console.log("get_locale")
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                if (state.locale == null){
                    this.$axios.get("app/session/get_locale/")
                    .then(response => {
                        commit("set_locale",
                            {
                                locale: response.data.locale,
                            })
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
                }
                else{
                    resolve()
                }
                
            })
        },

        async set_locale({ commit }, params) {
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.defaults.headers['Accept-Language'] = params.locale
                this.$axios.post("app/session/set_locale/", params)
                    .then(response => {
                        commit("set_locale",params)
                        
            
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}

export default ui;