<template>
  <div>
    <el-menu mode="horizontal" background-color="#334762" text-color="#FFF">
      <router-link :to="{ path: '/' }">
        <el-menu-item index="0">
          <img src="/static/media/Miandum.White.HOR.png" height="55" />
        </el-menu-item>
      </router-link>
      <NavbarLink
        v-for="(m, index) in modules"
        :module="m.code"
        :text="m.name"
        :key="m.code"
        :index="(index + 999).toString()"
      />
      <NavbarMenu index="50" :text="username" aclass="right" />
      <el-menu-item index="1" style="display:none;"></el-menu-item>
    </el-menu>
    <div class="line"></div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { uiHelpers } from "../ui_helpers";
import NavbarLink from "./NavbarLink.vue";
import NavbarMenu from "./NavbarMenu.vue";

export default {
  components: {
    NavbarLink,
    NavbarMenu,
  },
  setup() {
    const store = useStore();
    const { getmoduleslist, modules } = uiHelpers("modules");
    getmoduleslist();
    const username = computed(
      () => store.state.auth.first_name + " " + store.state.auth.last_name
    );
    return {
      username,
      modules,
    };
  },
};
</script>
<style scoped>
ul > li.right {
  margin-left: auto;
}
</style>
