<template>
    <el-dialog v-model="historyDialogVisible" title="Vēsture">
        <el-timeline style="max-width: 600px">
            <el-timeline-item :timestamp="item.created_time" placement="top" :key="item.id" v-for="item in data">
                <el-card>
                    <h4>{{item.username}}</h4>
                    <div v-if="item.action==3">{{item.comment}}</div>
                    <div v-else >{{ item.action == 2 ? 'atjaunoja' : 'izveidoja' }} ierakstu</div>
                    <el-table v-if="item.changes" :data="JSON.parse(item.changes)" max-height="200" size="small">
                        <el-table-column prop="field" label="Lauks" width="180" />
                        <el-table-column prop="old" label="Vecā vērtība" width="180" />
                        <el-table-column prop="new" label="Jaunā vērtība" />
                    </el-table>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </el-dialog>
</template>

<script setup>
    import { computed, inject } from 'vue';
import { useStore } from 'vuex';

    const store = useStore()
    let historyDialogVisible = inject('historyDialogVisible')

    const data = computed(() => store.state.api.history)
</script>
    
<style scoped>
    h4{
        margin-top: 0;
    }
</style>
