import { ElMessage } from 'element-plus'
import store from './store/store.js'
import i18n from './locales.js'
export const messages = () => {
  const showerorr = (msg) => {
    let text = '';
    let data
    let stat
    let type = 'warning'
    try{
      
      if (msg.response !== undefined){
        data = msg.response.data.error;
        stat = msg.response.status
      }
      else if (msg.data !== undefined && msg.status !== undefined){
        data = msg.data.error;
        stat = msg.status;
      }
    }catch{
      return   
    }
    if (stat == 401){
      if (data.message !== undefined){
        text = data.details.detail
      }
      else{
        return
      }
    }
    else if (stat == 403){
      if (data.constructor == Object){
        if (data.code !== undefined & (data.code.code == "token_not_valid")){
          text = "Session ended"
        }
        if (data.code !== undefined & (data.code == "not_authenticated")){
          text = data.message
        }
        if (data.message !== undefined && data.code == 'permission_denied'){
          text = data.message
        }
      }else{
        text = data.code + '. ' + data.message
      }
    }
    else if (stat == 400){
      if (typeof data === 'string' || data instanceof String){
        text = data
      } else{
      Object.entries(data.details).forEach(([key, value]) => {
        // console.log(key,value)
        if (value.constructor == Array){
          value.forEach(function (item, index) {
            if (item.constructor == Object){
              Object.entries(item).forEach(([key, value]) => {
                text += i18n.t('misc.line') + " " + (index+1) + ". "+ store.state.ui.field_translations[key] + ": " + value + "<br />" 
              })
            }else{
              text += i18n.t('misc.line') + " " + (index+1) + ". "+ store.state.ui.field_translations[key] + ": " + value + "<br />" 
            }
          })
        } else{
          text += store.state.ui.field_translations[key] + ": " + value + "<br />" 
        }
      })
    }
      // console.log(text)
    }else if (stat == 500){
      console.log(data)
      type = 'error'
      text = data['traceback'].replace("\n", "<br />")
    }
    else{
      text = msg.message;
    }
    ElMessage({
      message: text,
      type: type,
      grouping: true,
      center: true,
      duration: 7000,
      showClose: true,
      dangerouslyUseHTMLString: true
      
    })
  };

  const notify = (type, title) => {
    ElMessage({
        message: title,
        type: type,
        grouping: true,
        duration: type == 'success' ? 1500 : 10000
    })
  };

  return {showerorr,notify}
}