<template>
  <div>
    <el-form-item label="File" v-if="filelist.length > 1">
    <el-select-v2
      v-if="filelist.length > 1"
      v-model="selection"
      :options="filelist"
      placeholder="Please select"
      size="small"
      @change="update_pdf"
      value-key="value.id"
    />
  </el-form-item>
    <div style="width:900px !important;margin: 0 auto;">
     
    <div>
      <pdf :src="loadingTask" v-for="i in pageCount" :key="i" :page="i"></pdf>
    </div>
      
    </div>
  </div>
</template>


<script>
import { ref, computed, watch } from 'vue'
import pdf from 'vue3-pdf'
import { apiHelpers } from "../helpers";
import { messages } from '../popups.js'
export default {
  name: "PDFView",
  components: { pdf },
  setup() {
    const { showerorr,notify } = messages()
    
    const filelist = computed(() => {
      return store.getters['api/filelist'].map(f => { return { label: f.docfile.split("/").at(-1), value: {url: f.docfile,id: f.id}} })
    });
    let pageCount = ref(0)
    let currentPage = ref(1)
    let loadingTask = ref({})
    const { store } = apiHelpers();
    const selection = ref(null)

    const get_url = (id) =>{
      if (store.state.api.filelist.length > 0) {
        if (id) {
          return store.state.api.filelist.filter(item => {return item.id === id})[0].docfile
        } else{
          return store.state.api.filelist[0].docfile
        }
        
        // 
      } else {
        return null
      }
    }

     const update_pdf = async (value) =>{
      store.dispatch('api/after_edit', { field: 'pdf_file', value: value, grid: null, row: -1,view:'record_details' })
        .then(() => { })
        .catch(err => {
          showerorr(err)
        })
        loadfile(get_url(value))
    }
    const loadfile = async (url) => {
      if (url == null) {
        return
      }
      await store.dispatch('api/filedownload', { url: url, display: true })
        .then((response) => {
          if (response != null) {
            response.arrayBuffer()
              .then((buf) => {
                let uint8View = new Uint8Array(buf);
                loadingTask.value = pdf.createLoadingTask({ data: uint8View });
                loadingTask.value.promise.then(pdf => {
                  pageCount.value = pdf.numPages;
                });


              })
          }
        })
        .catch(() => {
          notify('warning', "Could no load PDF")
        })
    }
    watch(filelist, async (currentValue, oldValue) => {
      if (currentValue == oldValue){
        return
      }
      if (currentValue.length == 0){
        loadingTask.value = {}
      }
      
      selection.value = store.state.api.record_details.pdf_file
      await loadfile(get_url(store.state.api.record_details.pdf_file));
    });

    return {
      loadingTask,pageCount,currentPage,selection,filelist,update_pdf
    }
  }

};
</script>

<style>
</style>
