<template>
    <div v-loading.fullscreen.lock="loading">
        <el-row>
      <el-col class="details_style">
        <el-row>
          <el-col :span="24">
            <ContextbarReports />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <ModelDetails :model="f" type="R" />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
        
    </div>
        

</template>

<script setup>
import { onMounted, provide, ref} from 'vue'
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { uiHelpers } from "../ui_helpers";
import ModelDetails from "../components/ModelDetails.vue";
import ContextbarReports from "../components/ContextbarReports.vue";
import { messages } from '@/popups.js'
const route = useRoute()
const store = useStore();
const f = route.params.function
const { getviewmetadata } = uiHelpers(f);
const { showerorr } = messages()
const loading = ref(false)
const run_report = async () => {
  loading.value = true
  let params = JSON.parse((JSON.stringify(store.state.api.record_details)))
  delete params["data"]
  store.dispatch("api/get", { url: "/reports/"+f+"/display/", type: 'report', q:params })
      .then(()=>{
        loading.value = false
      })
      .catch(err => {
        loading.value = false
        showerorr(err)
      })

  }
const excel_export = async () => {
  loading.value = true
  let params = JSON.parse((JSON.stringify(store.state.api.record_details)))
  delete params["data"]
  store.dispatch("api/filedownload", { url: "/reports/"+f+"/excel/", type: 'report', q: params, display: false })
      .then(()=>{
        loading.value = false
      })
      .catch(err => {
        loading.value = false
        showerorr(err)
      })

  }
  
provide("run_report",run_report)
provide("excel_export",excel_export)
onMounted(async () => {
    await getviewmetadata(f);
})
</script>
    
<style>

</style>
