import { createWebHistory, createRouter } from "vue-router";
import Home from './views/Home'
import Login from './views/Login'
import Logout from './views/Logout'
import Model from './views/Models'
import ModelPopup from './views/ModelsPopup'
import Functions from './views/Functions'
import Exports from './views/Exports'
import Modules from './views/Modules'
import Settings from './views/Settings'
import Misc from './views/Misc'
import Reports from './views/Reports'
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresLogin: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/profile',
        name: 'profile',
        component: Home
    },
    {
        path: '/:module/~/:model/:key?',
        name: 'data',
        component: Model
    },
    {
        path: '/:model/:key?',
        name: 'data',
        component: Model
    },
    {
        path: '/pop/:model/:key?',
        name: 'popup',
        component: ModelPopup
    },
    {
        path: '/s/:model',
        name: 'settings',
        component: Settings
    },{
        path: '/misc/:model',
        name: 'misc',
        component: Misc
    },
    {
        path: '/modules/:module?/:section?',
        name: 'modules',
        component: Modules
    },
    {
        path: '/functions/:function',
        name: 'functions',
        component: Functions
    },
    {
        path: '/exports/:function',
        name: 'exports',
        component: Exports
    },
    {
        path: '/imports/:function',
        name: 'imports',
        component: Functions
    },
    {
        path: '/r/:function',
        name: 'reports',
        component: Reports
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});



export default router;