<template>
  <el-table :show-header="false"  :data="legend" size="mini" :row-class-name="get_legend_class">
    <el-table-column
      prop="text"
      label="Leģenda"
    ></el-table-column>
  </el-table>
</template>


<script>
export default {
  setup() {
    const legend = [
      {
        text : 'Brīdinājums',
        class: 'warning-row'
      },
      {
        text : 'Apstiprināts rēķins',
        class: 'success-row'
      },
      {
        text : 'Noraidīts rēķins',
        class: 'error-row'
      },
      {
        text : 'Apstiprināšana uzsākta',
        class: 'highlite-row'
      },
      {
        text : 'Apmaksāts un neapstiprināts, kavēts',
        class: 'extra_warning-row'
      }
    ]
    const get_legend_class = (row) =>{
      // console.log(row)
      return row.row.class    
    }
   
    return {
      legend,get_legend_class
    };
  },
};
</script>

<style>
.el-table .cell {
  word-break: keep-all !important;
}
.el-table .warning-row {
  background-color: #ddccb2;
}
.el-table .success-row {
  background-color: #acc59f;
}
.el-table .error-row {
  background-color: #ffdcdc;
}
.el-table .highlite-row {
  background-color: #daebfc;
}
.el-table .extra_warning-row {
  background-color: #fd8181;
}

</style>
