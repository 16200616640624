
<template>
    <el-dialog v-model="fileDialogVisible" title="Attachements">
        <el-upload :http-request="uploadfile" :on-remove="fileRemove" :on-preview="openfile" :file-list="filelist">
            <el-button v-if="!store.state.auth.read_only" size="small" type="primary">Choose file</el-button>
        </el-upload>
    </el-dialog>
</template>

<script>
// import { Document } from '@element-plus/icons'
import { inject, computed } from 'vue'
import { apiHelpers } from "../helpers";
import { messages } from '../popups.js'
export default {
    name: "Attachements",
    setup() {
        const { store } = apiHelpers();
        let fileDialogVisible = inject('fileDialogVisible')
        const filelist = computed(() => {
            return store.state.api.filelist.map(f => { return { name: f.docfile.split("/").at(-1), url: f.docfile, id: f.id } })
        });
        const { notify } = messages()
        const uploadfile = (file) => {

            store.dispatch('api/fileUpload', file)
                .then(() => {
                    notify('success', "Success")
                })
                .catch(() => {
                    notify('warning', "bad bad")
                })
        }
        const fileRemove = (file) => {
            store.dispatch('api/filedelete', { key: file.id })
                .then(() => {
                    notify('success', "Success")
                })
                .catch(() => {
                    notify('warning', "bad bad")
                })
        }
        const openfile = (file) => {
            store.dispatch('api/filedownload', { url: file.url, display: false })
                .then((response) => {
                    if (response == null) {
                        notify('success', "Success")
                    }


                })
                .catch(() => {
                    notify('warning', "bad bad")
                })
        }

        return { fileDialogVisible, uploadfile, fileRemove, openfile, filelist,store}
    },
};
</script>

<style scoped>
</style>
