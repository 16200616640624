<template>
  <el-row>
    <el-tabs tab-position="left" type="card" :stretch="true"  style="margin: 10px 0 0 0; height: calc(100vh - 70px); width: calc(100vw - 8px);" @tab-click="section_changed" v-model="section">
      <el-tab-pane lazy :label="$t('misc.data')" name="data">
        <NavigationIcon v-for="f in l_data" :url="'/' + f.code" icon="bi bi-person-fill" :text="f.txt" :key="f.code"
          :type="f.type" />
      </el-tab-pane>
      <el-tab-pane lazy :label="$t('misc.settings')" name="settings">
        <NavigationIcon v-for="f in l_settings" :url="(f.is_singleton ? '/s/' : '/') + f.code" icon="bi bi-person-fill" :text="f.txt" :key="f.code"
          :type="f.type" />
      </el-tab-pane>
      <el-tab-pane v-if="!store.state.auth.read_only" lazy :label="$t('misc.functions')" name="functions">
        <NavigationIcon v-for="f in l_functions" :url="'/' + f.code" icon="bi bi-person-fill" :text="f.txt"
          :key="f.code" :type="f.type" />
      </el-tab-pane>
      <el-tab-pane lazy :label="$t('misc.reports')" name="reports">
        <NavigationIcon v-for="f in l_reports" :url="f.code" icon="bi bi-person-fill" :text="f.txt" :key="f.code"
          :type="f.type" />
      </el-tab-pane>
      <el-tab-pane lazy :label="$t('misc.exports')" name="exports">
        <NavigationIcon v-for="f in l_exports" :url="'/' + f.code" icon="bi bi-person-fill" :text="f.txt" :key="f.code"
          :type="f.type" />
      </el-tab-pane>
      <el-tab-pane v-if="!store.state.auth.read_only" lazy :label="$t('misc.imports')" name="imports">
        <NavigationIcon v-for="f in l_imports" :url="'/' + f.code" icon="bi bi-person-fill" :text="f.txt"
          :key="f.code" />
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';
import NavigationIcon from "../components/NavigationIcon.vue";
import { uiHelpers } from "../ui_helpers";
export default {
  components: {
    NavigationIcon,
  },
  name: "Modules",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore()
    const module = route.params.module;
    const section = route.params.section || 'data';
    const {
      getfunctionslist,
      l_data,
      l_settings,
      l_functions,
      l_reports,
      l_exports,
      l_imports
    } = uiHelpers("view");
    getfunctionslist(module);

    const section_changed = (pane) => {
      router.push({ params: { section: pane.props.name } })
    }
    return {
      module,
      l_data,
      l_settings,
      l_functions,
      l_reports,
      l_exports,
      l_imports,
      section,
      section_changed,
      store
    };
  },
};
</script>

<style>
#content {
  margin: 0.5rem;
}
</style>
