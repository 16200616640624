
<template>
    <div>
        <el-dialog v-model="dialogvisible" title="Dialog" @open="open" @close="close">
            <el-form label-width="100px" size="mini">
                <div id="dialogcontents" v-if="initiated_meta">
                    <el-row v-for="(motherrow, motherrowindex) in view_dialog.fields" :key="motherrowindex">
                        <el-col  v-for="(mothercol, motherindex) in motherrow" :key="motherindex" :xs="24" :sm="24" :md="24/motherrow.length" :lg="24/motherrow.length" :xl="24/motherrow.length">
                        
                            <el-row v-for="(row, indexr) in mothercol" :key="indexr">
                                <el-col
                                    v-for="(col, indexc) in row"
                                    :key="indexc"
                                    :xs="calcwidth(col.w, 24)"
                                    :sm="calcwidth(col.w, 12)"
                                    :md="calcwidth(col.w, 8)"
                                    :lg="calcwidth(col.w, 6)"
                                    :xl="calcwidth(col.w, 4)"
                                >
                                    <InputField
                                        v-if="col.t !== 'Grid'"
                                        :meta="col"
                                        vmodel="dialog_details"
                                    />
                                    <Grid v-else-if="col.t == 'Grid'" :meta="col" vmodel="dialog_details" />
                                </el-col>
                            </el-row>
                        
                        </el-col>
                    </el-row>
                    
                    
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogvisible = false">Cancel</el-button>
                    <el-button type="primary" @click="ok">OK</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { inject, computed } from 'vue'
import { uiHelpers } from "../ui_helpers";
import InputField from "../components/InputField.vue";
import Grid from "../components/Grid.vue";
export default {
    name: "DialogWindow",
    components: {
        InputField,
        Grid,
    },
    setup() {
        let dialogvisible = inject('dialogvisible')
        let current_dialog_view = inject('current_dialog_view')
        let dialog_params = inject('dialog_params')

        const runFunction = inject('runFunction')
        const { getdialogmetadata, view_dialog, store } = uiHelpers();
        const open = () => {
            getdialogmetadata(current_dialog_view.value);
        }

        const ok = () => {
            runFunction('backend_method', { method: dialog_params.value.method, status: dialog_params.value.status })
            dialogvisible.value = false
        }
        const close = () => {
            store.commit("api/clear_dialog")
        }
        const calcwidth = (w, d) => {
            return typeof (w) !== 'undefined' && w !== null ? w : d
        }
        const initiated_meta =computed(() => store.state.api.dialog_details_initiated);
        return { dialogvisible, open, view_dialog, calcwidth, runFunction, dialog_params, close, ok ,initiated_meta}
    },
};
</script>

<style scoped>
</style>
