<template>
  <div v-if="steps.length > 0">
    <el-steps process-status="process" align-center>
      <el-step
        v-for="(s, index) in steps"
        :status="processstatus(s.status)"
        :title="s.username"
        :description="s.comment"
        :key="index"
      ></el-step>
    </el-steps>
  </div>
</template>


<script>
import { apiHelpers } from "../helpers";
// import { Search, Delete, Plus } from "@element-plus/icons";
import { computed, ref } from "vue";
export default {
  name: "Steps",
  props: {
    meta: Object,
    vmodel: String
  },
  setup(props) {
    const { store } = apiHelpers(props);
    const steps = computed(() => {
      return store.state.api[props.vmodel][props.meta.f] != null ? store.state.api[props.vmodel][props.meta.f] : []
    })
    const statuses = {
      4: "process",
      1: "wait",
      5: "success",
      3: "error"
    }
    const processstatus = (status) => {
      return statuses[status]
    }
    let gettrue = ref(true)
    return {
      store, steps, processstatus, gettrue
    };
  },
};
</script>

<style>
/* .steps {
  padding: 0 10px;
} */

.el-step__icon-inner.is-status {
  margin-top: -2px;
}
</style>
