<template>
  <el-form-item :label="label">
    <div v-if="props.meta.t == 'CharField' || props.meta.t == 'CodeCharField' || props.meta.t == 'ForeignKey'">
      <div v-if="hasLookup">
        <el-input :class="{is_mandatory: is_mandatory}" :disabled="is_disabled" v-model="store.state.api[props.vmodel][props.meta.f]" @keyup.f2="open_record($event,props.meta.m)" @change="after_edit" @input="restrict_specials($event,props.meta.t)">
          <template #suffix v-if="!is_disabled && props.meta.m !== null && props.meta.m">
            <el-icon class="el-input__icon" @click="openSelect(props.meta.m, props.meta.f)">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <div v-else>
        <el-input :class="{is_mandatory: is_mandatory}" :disabled="is_disabled"  v-model="store.state.api[props.vmodel][props.meta.f]" @change="after_edit" @keydown="restrict_specials($event,props.meta.t)"></el-input>
      </div>
    </div>
    <div v-else-if="props.meta.t == 'BooleanField'">
      <el-checkbox :disabled="is_disabled"  v-model.lazy="store.state.api[props.vmodel][props.meta.f]" :label="label_checkbox" size="small"
        @change="after_edit"></el-checkbox>
    </div>

    <div v-else-if="props.meta.t == 'DateField'">

      <el-date-picker :class="{is_mandatory: is_mandatory}" :disabled="is_disabled" v-model="store.state.api[props.vmodel][props.meta.f]" type="date" value-format="YYYY-MM-DD"
        format="DD.MM.YYYY" :clearable="clearable" @change="after_edit" />
    </div>
    <div v-else-if="props.meta.t == 'DateRangeField'">
    <el-date-picker 
    :disabled="is_disabled" 
    v-model="store.state.api[props.vmodel][props.meta.f]" 
    type="daterange" 
    value-format="YYYY-MM-DD"
    format="DD.MM.YYYY" 
    range-separator="-"
    @change="after_edit" />
    </div>
    
    <div v-else-if="props.meta.t == 'TimeField'">
      <el-time-select :class="{is_mandatory: is_mandatory}" :disabled="is_disabled" v-model="store.state.api[props.vmodel][props.meta.f]" @change="after_edit" step="00:05"
        start="00:00" end="23:59" />
    </div>

    <div v-else-if="props.meta.t == 'DropdownInteger'">
      <el-select :disabled="is_disabled" v-model="store.state.api[props.vmodel][props.meta.f]"
        @change="after_edit">
        <el-option
          v-for="item in menus[props.meta.menu]"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :class="{is_mandatory: is_mandatory}"
        />
      </el-select>
    </div>

    <div v-else-if="props.meta.t == 'DecimalField'">
      <input :class="{is_mandatory: is_mandatory}" :disabled="is_disabled"  type="number" v-model.number="store.state.api[props.vmodel][props.meta.f]" @change="after_edit($event.target.value)" />
    </div>
    <div v-else-if="props.meta.t == 'IntegerField' || props.meta.t == 'BigIntegerField'">
      <input :class="{is_mandatory: is_mandatory}" :disabled="is_disabled"  type="number" v-model.number="store.state.api[props.vmodel][props.meta.f]" @change="after_edit($event.target.value)" />
    </div>
    <div v-else-if="props.meta.t == 'TextField'">
      <el-input :class="{is_mandatory: is_mandatory}" :disabled="is_disabled"  v-model="store.state.api[props.vmodel][props.meta.f]" @change="after_edit" autosize type="textarea"></el-input>
    </div>
    <div v-else-if="props.meta.t == 'PasswordField'">
      <el-input :class="{is_mandatory: is_mandatory}" :disabled="is_disabled"  v-model="store.state.api[props.vmodel][props.meta.f]" @change="after_edit" type="password" show-password></el-input>
    </div>
    <div v-else>
      <el-input :class="{is_mandatory: is_mandatory}" :disabled="is_disabled"  v-model="store.state.api[props.vmodel][props.meta.f]" @change="after_edit"></el-input>
    </div>
  </el-form-item>
</template>



<script>
import { apiHelpers } from "../helpers";
import { uiHelpers } from "../ui_helpers";
import { Search } from "@element-plus/icons";
import { inject, computed, ref } from "vue";
import { messages } from '@/popups'
import { useRouter } from "vue-router";
export default {
  name: "InputField",
  props: {
    meta: Object,
    key: String,
    labelText: String,
    vmodel: String,
    type: String
  },
  inheritAttrs: false,
  components: {
    Search,
  },
  setup(props) {
    const select_model = inject("select_model");
    const currentfield = inject("currentfield");
    const currentrow = inject("currentrow");
    const currentgrid = inject("currentgrid");
    const lookupvisible = inject("lookupvisible");
    const current_detail_store = inject("current_detail_store");
    const { store } = apiHelpers();
    const router = useRouter();
    const { menus } = uiHelpers(props.vmodel);
    const { showerorr } = messages()
    const openSelect = (m, f) => {
      if (props.meta.ro !== 'undefined' && props.meta.ro !== null && props.meta.ro){
        return
      }
      select_model.value = m;
      currentfield.value = f;
      currentrow.value = -1;
      currentgrid.value = null;
      current_detail_store.value = props.vmodel
      lookupvisible.value = !lookupvisible.value;
    };
    const after_edit = (value) => {
      store.dispatch('api/after_edit', { field: props.meta.f, value: value, grid: null, row: -1, view: props.vmodel })
        .then(() => { })
        .catch(err => {
          showerorr(err)
        })
    }

    const restrict_specials = (e,type) => {
      if (type == 'CodeCharField'){
        
        if ('^/ .<>\\ `{|}"'.indexOf(e.key) > -1){
          e.preventDefault();
        }
      }
      
    }

    const open_record = (e,model) => {
      if (e.target.value > ''){
        let routeData = router.resolve({name: 'popup', params: {model: model,key: e.target.value}});
        window.open(routeData.href,'popup'+model+e.target.value, 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1100,height=700');
      }
      
    } 
    const is_mandatory = computed(() => props.meta.mandat && !store.state.api[props.vmodel][props.meta.f]);
    const hasLookup = computed(() => props.meta.m !== null && props.meta.m);
    const is_disabled = computed(() => (store.state.auth.read_only && props.type != "R") || (props.meta.ro !== 'undefined' && props.meta.ro !== null && props.meta.ro));
    const controls = ref(false)
    const clearable = ref(false)
    const prefix_icon = ref(null)
    const min = ref(null)
    const label = computed(() => { return props.meta.t == 'BooleanField' ? "" : props.meta.text })
    const label_checkbox = computed(() => { return props.meta.text })
    return {
      props,
      openSelect,
      hasLookup,
      store,
      menus,
      controls,
      prefix_icon,
      clearable,
      min,
      label,
      after_edit,
      label_checkbox,
      restrict_specials,
      open_record,
      is_disabled,
      is_mandatory
    };
  },
};
</script>
<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}

#recordheader .el-input--suffix .el-input__inner,
#dialogcontents .el-input--suffix .el-input__inner {
  padding-right: 25px !important;
}

#recordheader .el-date-editor .el-input__inner{
  padding-right: 5px !important;
}

#recordheader .checkbox,
#dialogcontents .checkbox {
  margin-left: 7px;
}

#recordheader .el-input-group,
#dialogcontents .el-input-group {
  padding: 0 5px;
}

.el-form-item--mini.el-form-item {
  margin-bottom: 5px;
  margin-right: 5px;
}

#recordheader .el-input__inner:not(),
#dialogcontents .el-input__inner:not() {
  padding: 0 7px !important;
}

.el-input-number .el-input__inner {
  text-align: left !important;
}

.el-form-item__label {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
  padding: 0 5px 0 0 !important;
  width: 120px !important;
  }

.el-select{
  display: block !important;
}
.el-checkbox{
  margin-left: 20px;
}

#recordheader  input:disabled {
  background-color:#f5f7fa;
  color: rgb(128, 128, 128);
}

#recordheader .is_mandatory input {
  background-color: #fceedb;
}
</style>