<template>
  <el-sub-menu :index="index" v-bind:class="aclass">
    <template #title>{{ text }}</template>
    <el-menu-item @click="$router.push({ name: 'logout' })" index="50-1">{{ $t('navbar.logout') }}</el-menu-item>
    <el-sub-menu index="50fa-rotate-270">
      <template #title>{{ $t('navbar.language') }}</template>
      <el-menu-item index="50-2-1" @click="setLocale('lv')">Latviešu</el-menu-item>
      <el-menu-item index="50-2-2" @click="setLocale('en')">English</el-menu-item>
    </el-sub-menu>
  </el-sub-menu>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from "vue3-i18n";
export default {
  name: "NavbarMenu",
  props: {
    text: String,
    index: String,
    aclass: String,
  },
  setup() {
    const store = useStore()
    const i18n = useI18n();
    const setLocale = (locale) => {
      store.dispatch("ui/set_locale", { locale: locale })
      i18n.setLocale(locale);
    }
    return {
      setLocale
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
