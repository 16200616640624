<template>
  <div id="model_view">
    <el-row>
      <el-col class="details_style">
        <el-row>
          <el-col :span="24">
            <Contextbar />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <ModelDetails :model="model" type="D" />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <DialogWindow />
  </div>
</template>


<script>
import ModelDetails from "../components/ModelDetails.vue";
import DialogWindow from '../components/DialogWindow'
import Contextbar from "../components/Contextbar.vue";
// import { ElMessage } from 'element-plus'
import { apiHelpers } from "../helpers";
import { uiHelpers } from "../ui_helpers";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, onUnmounted, watch, provide, inject, ref } from "vue";
import { messages } from '../popups.js'
// import { strictEqual } from "assert";
export default {
  name: "Models",
  components: {
    Contextbar,
    ModelDetails,
    DialogWindow
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const model = route.params.model;
    const { getrecorddetails, store } = apiHelpers(model);
    const { showerorr, notify } = messages()
    const { getmodelmetadata } = uiHelpers(model);
    const { ws_send } = inject('ws_connection')

    onMounted(async () => {
      await getmodelmetadata(route.params.key);
    });
    onUnmounted(async () => {
      store.commit("api/reset");
      store.commit("ui/reset");
    });

    const keys = computed(() => {
      return Array.from(
        store.state.api.keys,
        (x) => store.state.api.record_details[x]
      ).join("~");
    });

    const heading = computed(() => {
      return store.state.api.status > ""
        ? route.params.model +
        ": " +
        keys.value +
        "(" +
        store.state.api.status +
        ")"
        : route.params.model + ": " + keys.value;
    });

    const prev_record = async () => {
      store
        .dispatch("api/get_next_prev", { method: "prev" })


    };
    const next_record = async () => {
      store
        .dispatch("api/get_next_prev", { method: "next" })
    };

    const prev_or_next = async () => {
      store
        .dispatch("api/get_next_prev", { method: "prev_or_next" })
    };

    const new_record = async () => {
      await router.push({ params: { key: null } });
      store.commit("api/newRecord");
    };
    const cancel_new_record = async () => {
      await router.push({ params: { key: null } });
      store.commit("api/noRecord");
    };

    const delete_record = () => {
      store
        .dispatch("api/delete", {
          model: model,
        })
        .then(() => {
          notify('success', "Deleted")
          prev_or_next();
        })
        .catch((err) => {
          showerorr(err);
        });
    };
    const save_record = () => {
      store
        .dispatch("api/post_put", {
          model: model,
          key: keys.value
        })
        .then(() => {
          ws_send("jeee")
          notify('success', "Saved");
        })
        .catch((err) => {
          showerorr(err);
        });
    };
    const cancelChanges = async () => {
      if (store.state.api.status == "M") {
        await getrecorddetails(keys.value);
      } else {
        cancel_new_record();
      }
    };

    
    let dialogvisible = inject('dialogvisible')
    let current_dialog_view = inject('current_dialog_view')
    let dialog_params = ref({})
    const backend_method = (params) => {
      if (params.dialog !== 'undefined' && params.dialog > '') {
        dialog_params.value = params

        current_dialog_view.value = params.dialog
        dialogvisible.value = true
        return
      }
      store
        .dispatch("api/backend_method", params)
        .then(() => {
          notify('success', "Success");
        })
        .catch((err) => {
          showerorr(err);
        });
    };

    const runFunction = (method, params) => {
      if (method == "new_record") new_record()
      if (method == "delete_record") delete_record()
      if (method == "save_record") save_record()
      if (method == "cancelChanges") cancelChanges()
      if (method == "prev_record") prev_record()
      if (method == "next_record") next_record()
      if (method == "backend_method") backend_method(params)
    };

    watch(
      () => route.params.key,
      async (newkey) => {
        await getrecorddetails(newkey);
      }
    );
    provide("runFunction", runFunction)
    provide("dialog_params", dialog_params)
    return {
      heading,
      keys,
      route,
      model
    };
  },
};
</script>


<style scoped>
#content {
  margin: 0 !important;
}
.el-row {
  margin-bottom: 0px !important;
}
.details_style {
  border-left: 2px solid rgb(146 149 161);
}
</style>
