<template>
  <div>
    <el-affix :offset="0">
      <el-row id="contextbar">
        <el-col :span="12">
          <el-button-group style="float: left">
            <el-tooltip :content="$t('contextbar.files')" placement="top" effect="light">
              <el-button :class="attach_button" @click="fileDialogVisible = true" :icon="Files" element-loading-text
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('contextbar.actions')" placement="top" effect="light">
              <el-dropdown trigger="click">
                <el-button :icon="Setting" :disabled="!cancel_disabled"
                  style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff; "></el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="a in actions" :key="a.function"
                      @click="runFunction('backend_method', { method: a.function, status: a.status, dialog: a.dialog })">
                      {{ translation(a.t) || a.name }}
                    </el-dropdown-item>
                  <el-dropdown-item
                      @click="show_history">
                      {{ $t('contextbar.history') }}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-tooltip>
            <el-tooltip :content="$t('contextbar.prev')" placement="top" effect="light">
              <el-button @click="runFunction('prev_record')" :icon="ArrowLeft"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('contextbar.next')" placement="top" effect="light">
              <el-button @click="runFunction('next_record')" :icon="ArrowRight"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
          </el-button-group>
          
          <!-- <el-button-group style="float: left">
            <el-tooltip :content="$t('contextbar.history')" placement="top" effect="light">
              <el-button @click="show_history" :icon="View"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
          </el-button-group> -->

        </el-col>

        <el-col :span="12">
          <!-- <el-menu mode="horizontal"> -->
          <el-button-group style="float: right">
            <el-tooltip :content="$t('contextbar.delete')" placement="bottom" effect="light">
              <el-popconfirm 
              :title="$t('contextbar.comfirm_delete')" 
              @confirm="runFunction('delete_record')"
              :confirm-button-text="$t('common.yes')"
              :cancel-button-text="$t('common.no')">
              
                <template #reference>
                  <el-button :disabled="delete_disabled" :icon="Delete"
                            style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
                </template>
              </el-popconfirm>
              
            </el-tooltip>
            <el-tooltip :content="$t('contextbar.cancel')" placement="bottom" effect="light">
              <el-button :disabled="cancel_disabled" @click="runFunction('cancelChanges')" :icon="CircleClose"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('contextbar.new')" placement="bottom" effect="light">
              <el-button :disabled="new_disabled" @click="runFunction('new_record')" :icon="CirclePlus"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('contextbar.save')" placement="bottom" effect="light">
              <el-button :disabled="save_disabled" @click="runFunction('save_record')" :icon="Finished"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
          </el-button-group>
        </el-col>
      </el-row>
    </el-affix>
    <Attachements />
    <HistoryView />
  </div>
</template>

<script>
import Attachements from '@/components/Attachements';
import HistoryView from '@/components/HistoryView';
import { ArrowLeft, ArrowRight, CircleClose, CirclePlus, Delete, Files, Finished, Setting, View } from '@element-plus/icons'; // ArrowLeft, ArrowRight 
import { inject, provide, ref } from 'vue';
import { uiHelpers } from "../ui_helpers";
// Array.prototype.contains = function (obj) {
//   return this.indexOf(obj) > -1;
// };
export default {
  name: "Contextbar",
  components: {
    Attachements, HistoryView//, Files, ArrowLeft, ArrowRight, Setting//, ArrowLeft, ArrowRight
  },
  setup() {
    const { translation } = uiHelpers()
    const runFunction = inject('runFunction')
    let fileDialogVisible = ref(false)
    let historyDialogVisible = ref(false)

    provide('fileDialogVisible', fileDialogVisible)
    provide('historyDialogVisible', historyDialogVisible)
    const show_history = () => {
      runFunction('show_history')
      historyDialogVisible.value = true
      console.log("show_history")
    }
    return { runFunction, fileDialogVisible, show_history, translation, Delete, CirclePlus, Finished, CircleClose, Files, ArrowLeft, ArrowRight, Setting, View }
  },
  computed: {
    record_status() {
      if (this.$store.state.api.status == "")
        return "-"
      else
        return this.$store.getters['api/key']
    },
    actions() {
      return this.$store.getters['ui/actions']
    },
    save_disabled() {
      return !(["M", "C", "D"].indexOf(this.$store.state.api.status)>-1) || this.$store.state.auth.read_only;
    },
    new_disabled() {
      return ["M", "C", "D"].indexOf(this.$store.state.api.status)>-1 || this.$store.state.auth.read_only;
    },
    copy_disabled() {
      return !([""].indexOf(this.$store.state.api.status)>-1) | this.$store.state.auth.read_only;
    },
    delete_disabled() {
      return !(["R"].indexOf(this.$store.state.api.status)>-1) || this.$store.state.auth.read_only;
    },
    cancel_disabled() {
      return !(["M", "C", "D"].indexOf(this.$store.state.api.status)>-1) || this.$store.state.auth.read_only;
    },
    attach_button() {
      if (this.$store.state.api.filelist.length > 0)
        return "has_files"
      else
        return "no_files"
    }


  },
};
</script>
<style>
#contextbar {
  z-index: 1000 !important;
  -webkit-box-shadow: 0px 10px 13px -7px #bebebe,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #bebebe, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
</style>
<style scoped>
.el-menu--horizontal {
  height: 40px;
  background-color: #344863;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
  color: white !important;
  background-color: #344863 !important;
}

.el-menu--horizontal>.el-menu-item.is-active.has_files {
  color: #67c23a !important;
}

.el-menu--horizontal>.el-menu-item,
.el-menu--horizontal>.el-menu-item-custom:not(.has_files) {
  color: aliceblue !important;
  height: 39px;
  line-height: 39px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid rgb(98 98 98);
  text-align: center;
}

.el-sub-menu {
  border-right: 1px solid rgb(98 98 98);
}

.el-button-group>.has_files {
  background-color: #67c23a54 !important;
}

.el-menu--horizontal>.el-menu-item-short,
.el-menu--horizontal>.el-menu-item-short {
  width: 40px;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: var(--el-menu-hover-text-color);
  background-color: #445974;
}

.item1 {
  /* margin-left: calc(100vw - 320px) !important; */
  margin-left: auto !important;
  border-left: 1px solid rgb(98 98 98);
}

.el-menu--horizontal>>>.el-sub-menu>.el-sub-menu__title {
  height: 40px !important;
  line-height: 40px !important;
  color: white !important;
}

.el-menu--horizontal>>>.el-sub-menu>.el-sub-menu__title:hover {
  background-color: #344863;
}
</style>
