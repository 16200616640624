<template>
  <div>
    <div style="margin: 0 10px 0 10px;">
      <el-input  size="mini" v-model="store.state.ui.widgets[props.url].str" @change="update_store" placeholder="Filter..." clearable>
      </el-input>
    </div>
    <el-skeleton :loading="loading" :rows="15" animated/>
    <el-table
      :data="filtered_data"
      size="mini"
      style="cursor: pointer;"
      :row-class-name="tableRowClassName"
      header-row-class-name="widget_grid_header"
      @row-click="handle_click"
    >
      <el-table-column
        v-for="col in table_cols"
        :key="col.c"
        :prop="col.c"
        :label="col[store.state.ui.locale] || col.c"
        sortable
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
  
  </div>
</template>


<script>
//:class-name="is_hidden(col)"
import { messages } from '@/popups.js';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  props: {
    url: String
  },
  setup(props) {
    const store = useStore();
    store.commit("ui/widget_init",{widget: props.url})
    const { showerorr } = messages()
    let table_data = ref([])
    let table_cols = ref([])
    let loading = ref(true)
    let is_Active = true
    let selected = ref(null)
    const legend = [
      {
        text : 'Brīdinājums',
        class: 'warning-row'
      },
      {
        text : 'Apstiprināts rēķins',
        class: 'success-row'
      },
      {
        text : 'Noraidīts rēķins',
        class: 'error-row'
      },
      {
        text : 'Apstiprināšana uzsākta',
        class: 'highlite-row'
      },
      {
        text : 'Apmaksāts, neapstiprināts',
        class: 'extra_warning-row'
      }
    ]
    const get_legend_class = (row) =>{
      return row.row.class    
    }
    let refresh_task
    const getCols = async () => {
      store.$axios.get(props.url, { params: { t: 0 } }).
        then(response => {
          table_cols.value = response.data
        }).
        catch(err => console.log(err))
    }
    const getData = async () => {
      
      if (is_Active) {
        store.$axios.get(props.url, { params: { t: 1 } }).
          then(response => {
            table_data.value = response.data
            loading.value = false
          }).
          catch(err => {
            showerorr(err)
            loading.value = false
          })
          refresh_task = setTimeout(getData,60000)
        }
    }
    const update_store = (evt) =>{
      store.commit("ui/widget_init",{widget: props.url, str: evt})
    } 
    const change_sort = (evt) =>{
      store.commit("ui/widget_init",{widget: props.url, sort: evt})
    } 
    onMounted(async () => {
      await getCols()
      await getData()
      
         
    })
    onUnmounted(async () => {
      // console.log("onUnmounted")
      clearTimeout(refresh_task)
      is_Active = false
    })

    const filter_str = computed(() => store.state.ui.widgets[props.url].str)


    const filtered_data = computed(() => {
      if (table_data.value.length == 0){
        return []
      }
      return table_data.value.filter((a) =>{
        return Object.keys(a).some(function (k) {
          if (typeof a[k] === 'string' && ~a[k].toLowerCase().indexOf(filter_str.value.toLowerCase())) {
              return true;
          }
          if (typeof a[k] === 'number' && ~a[k] === filter_str.value) {
              return true;
          }
        });
      })
    });

    const tableRowClassName = ({ row, }) => {
      var res = ""
      if (row.display_status == "w") {
        res = 'warning-row'
      } else if (row.display_status == "s") {
        res = 'success-row'
      } else if (row.display_status == "e") {
        res = 'error-row'
      } else if (row.display_status == "p") {
        res = 'highlite-row'
      } else if (row.display_status == "x") {
        res = 'extra_warning-row'
      }
      if (store.state.ui.widgets[props.url].selected && row.onclick == store.state.ui.widgets[props.url].selected.onclick) {
        res += ' selected-row'
      } 

      return res
    }
    const router = useRouter();
    const handle_click = (row) => {
      store.commit("ui/widget_row_select",{widget: props.url, row: row})
      selected.value = row
      if (row.onclick !== 'undefined') {
        router.push(row.onclick)
      }

    }

    return {
      table_cols, 
      tableRowClassName,
       handle_click, 
       store, 
       legend,
       get_legend_class, 
       filter_str, 
       filtered_data,
       props,
       update_store,
       change_sort,
       loading
    };
  },
};
</script>

<style scoped>
.el-table >>> .cell {
  white-space: nowrap!important;
}
.el-table >>> .warning-row {
  background-color: #ddccb2;
}
.el-table >>> .success-row {
  background-color: #acc59f;
}
.el-table >>> .error-row {
  background-color: #ffdcdc;
}
.el-table >>> .highlite-row {
  background-color: #daebfc;
}
.el-table >>> .extra_warning-row {
  background-color: #fd8181;
}

.el-table >>> .selected-row {
  /* background-color: #87878713; */
  font-weight: 800;
}

.el-table >>> .extra_warning-row td.el-table__cell {
  border-bottom: 1px solid #d37e7e !important;
}

.el-table >>> .success-row td.el-table__cell {
  border-bottom: 1px solid #99af8e !important;
}

.el-table >>> .warning-row td.el-table__cell {
  border-bottom: 1px solid #c4b7a2 !important;
}

.el-table >>> .error-row td.el-table__cell {
  border-bottom: 1px solid #e3caca !important;
}

</style>
