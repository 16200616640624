<template>
  <div>
    <el-affix :offset="0">
      <el-row id="contextbarsettings">
        <el-col :span="24">
          <el-button-group style="float: right">

            <el-tooltip :content="$t('contextbar.cancel')" placement="bottom" effect="light">
              <el-button :disabled="cancel_disabled" @click="runFunction('cancelChanges')" :icon="CircleClose"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('contextbar.save')" placement="bottom" effect="light">
              <el-button :disabled="save_disabled" @click="runFunction('save_record')" :icon="Finished"
                style="border: none;border-left: 1px solid #ecf5ff;border-right: 1px solid #ecf5ff;"></el-button>
            </el-tooltip>
          </el-button-group>
        </el-col>
      </el-row>
    </el-affix>
  </div>
</template>

<script>
import { inject } from 'vue'
import { Setting, CirclePlus, Finished, CircleClose } from '@element-plus/icons'// ArrowLeft, ArrowRight 
import { uiHelpers } from "../ui_helpers";
// Array.prototype.contains = function (obj) {
//   return this.indexOf(obj) > -1;
// };
export default {
  name: "ContextbarSettings",
  setup() {
    const { translation } = uiHelpers()
    const runFunction = inject('runFunction')
    return { runFunction, translation, CirclePlus, Finished, CircleClose, Setting }
  },
  computed: {
    save_disabled() {
      return !(["M", "C", "D"].indexOf(this.$store.state.api.status)>-1);
    },
    cancel_disabled() {
      return !(["M", "C", "D"].indexOf(this.$store.state.api.status)>-1);
    },
  },
};
</script>
<style>
#contextbarsettings {
  z-index: 1000 !important;
  -webkit-box-shadow: 0px 10px 13px -7px #bebebe,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #bebebe, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
</style>
<style scoped>
.el-menu--horizontal {
  height: 40px;
  background-color: #344863;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
  color: white !important;
  background-color: #344863 !important;
}

.el-menu--horizontal>.el-menu-item.is-active.has_files {
  color: #67c23a !important;
}

.el-menu--horizontal>.el-menu-item,
.el-menu--horizontal>.el-menu-item-custom:not(.has_files) {
  color: aliceblue !important;
  height: 39px;
  line-height: 39px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid rgb(98 98 98);
  text-align: center;
}

.el-sub-menu {
  border-right: 1px solid rgb(98 98 98);
}

.el-button-group>.has_files {
  background-color: #67c23a54 !important;
}

.el-menu--horizontal>.el-menu-item-short,
.el-menu--horizontal>.el-menu-item-short {
  width: 40px;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: var(--el-menu-hover-text-color);
  background-color: #445974;
}

.item1 {
  /* margin-left: calc(100vw - 320px) !important; */
  margin-left: auto !important;
  border-left: 1px solid rgb(98 98 98);
}

.el-menu--horizontal>>>.el-sub-menu>.el-sub-menu__title {
  height: 40px !important;
  line-height: 40px !important;
  color: white !important;
}

.el-menu--horizontal>>>.el-sub-menu>.el-sub-menu__title:hover {
  background-color: #344863;
}
</style>
