<template>
    <el-drawer v-model="store.state.api.log_visible" title="Log" size="50%" @closed="logClosed">
        <table style="font-family: Monospace;  overflow: auto; width:100%;">
            <tr>
                <td><el-progress :percentage="store.state.api.progress" :stroke-width="20"/></td>
            </tr>
            <tr v-for="(row, index) in log" :key="index">
                <td>{{ row }}</td>
            </tr>
        </table>
    </el-drawer>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const log = computed(() => store.state.api.log)
const logClosed = () => {
    store.commit('api/close_log')
}
</script>
    
<style>
.el-drawer__body {
    overflow: auto !important;
}
</style>
