
const auth = {
    namespaced: true,
    state() {
        return {
            host: null,
            accessToken: null,
            refreshToken: null,
            ws_ticket: null,
            ws_channel: null,
            first_name: null,
            last_name: null,
            updating_token: false,
            refresh: null,
            dologout: false,
            ignore_auth: false,
            read_only: true
        }
    },
    mutations: {
        set_host(state, host){
            state.host = host
        },
        set_ws_channel(state,channel){
            state.ws_channel = channel
        },
        updating_token(state) {
            state.updating_token = true
        },
        updating_token_done(state) {
            state.updating_token = false
        },
        setToken(state, { access, refresh, first_name, last_name,ws_ticket,read_only}) {
            state.accessToken = access
            state.refreshToken = refresh
            state.first_name = first_name
            state.last_name = last_name
            state.ws_ticket = ws_ticket
            state.read_only = read_only
            state.dologout = false
            state.updating_token = false
            state.ignore_auth = false
        },
        updateToken(state, { access,ws_ticket }) {
            state.accessToken = access
            state.updating_token = false
            state.dologout = false
            state.ws_ticket = ws_ticket
        },
        update_ws_ticket(state, ticket) {
            state.ws_ticket =  ticket
        },
        destroyToken(state) {
            state.accessToken = null
            state.refreshToken = null
            state.first_name = null
            state.last_name = null
            state.ws_ticket = null
            state.updating_token = false
            state.dologout = true
            state.ignore_auth = false
            state.read_only = true
        },
        dologout(state) {
            state.dologout = true
        },
        ignore_auth(state) {
            state.ignore_auth = true
        },
        check_auth(state) {
            state.ignore_auth = false
        }
    },
    getters: {
        // need_to_kick_out(state){
        //     return state.accessToken == null && state.ignore_auth == false
        // },
        loggedIn(state) {
            return state.accessToken != null && state.dologout == false //TODO: check also exipry
        },
        updating_token(state) {
            return state.updating_token
        },
        dologout(state) {
            return state.dologout
        },
        ws_address(state) {
            return (location.protocol === 'https:' ? 'wss' : 'ws') + "://" + state.host + "/ws/?ticket_uuid="+state.ws_ticket
        },
        api_address(state) {
            return (location.protocol) + "//" + state.host + "/api"
        }
    },
    actions: {
        userLogout({ commit, state }) {
            commit('dologout')
            return new Promise((resolve) => {
                this.$axios.post('/app/api-token-destroy/', {
                    refresh: state.refreshToken,
                    ws_ticket: state.ws_ticket
                })
                .then(() =>{
                    commit('destroyToken')
                    resolve()
                })
                .catch(()=>{
                    commit('destroyToken')
                    resolve()
                })
            })
        },
        set_root_host({getters}) {
            this.$axios.defaults.baseURL = getters["api_address"]
        },
        userLogin({ state, commit }, usercredentials) {
            commit('ignore_auth')
            return new Promise((resolve, reject) => {
                this.$axios.post('/app/api-token/', {
                    username: usercredentials.username,
                    password: usercredentials.password,
                    ws_ticket: state.ws_ticket
                })
                    .then(response => {
                        commit('setToken', {
                            access: 'Bearer ' + response.data.access,
                            refresh: response.data.refresh,
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            ws_ticket: response.data.ticket_uuid,
                            read_only: response.data.read_only
                        })
                        this.commit('ui/set_locale', {
                            locale: response.data.ui_language
                        })
                        this.$axios.defaults.headers['Accept-Language'] = response.data.ui_language
                        this.$axios.defaults.headers.Authorization = state.accessToken
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        async refreshToken({ state, commit,getters  }) {
            console.log("refreshToken")
            // if (await dispatch('wait_new_token') > 0){
            //     return
            // }
            if (getters.updating_token){
                console.log("already refreshing. return")
                return true
            }
            commit('updating_token')
            await this.$axios.post('/app/api-token-refresh/', {
                refresh: state.refreshToken,
                ws_ticket: state.ws_ticket
            })
            .then(response => {
                
                commit('updateToken', {
                    access: 'Bearer ' + response.data.access,
                    ws_ticket: response.data.ticket_uuid
                })
                console.log("good refresh")
            })
            .catch(() => {
                console.log("bad refresh")
            })
            
        },
        async update_ws_ticket({ commit,state  }) {
            // console.log("refresh_ws_ticket")
            await this.$axios.post('/app/refresh-ws-ticket/',{
                refresh: state.refreshToken
            })
            .then(response => {
                commit('update_ws_ticket', response.data.ticket_uuid)
                // console.log("good ws refresh")
            })
            .catch(() => {
                console.log("bad ws refresh")
            })
            
        },
        async wait_new_token({ getters }, url) {
            let waited = 0
            while (
                getters.updating_token &&
                !getters.dologout &&
                waited < 4000 &&
                url != "/app/api-token-refresh/"
            ) {
                // console.log(url + " " + waited)
                await new Promise((r) => setTimeout(r, 10));
                waited += 10
            }
            if (waited > 0)
                return true
            else
                return false
        }
    }
}


export default auth