import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import api from '../store/apiService';
import auth from '../store/authService';
import ui from '../store/uiService';
const modules = createPersistedState({
    paths: ['auth.accessToken', 'auth.refreshToken', 'auth.ws_ticket', 
    'auth.first_name', 'auth.last_name', 'auth.read_only', 'auth.host','ui.locale', 'ui.widgets']
})
const store = createStore({

    modules: {
        auth,
        api,
        ui
    },
    plugins: [modules],
})

export default store;