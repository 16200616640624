<template>
  <div id="model_view">
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" class="details_style">
        <el-row>
          <el-col :span="24">
            <ContextbarSettings />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <ModelDetails :model="model" type="S" />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import ModelDetails from "../components/ModelDetails.vue";
import ContextbarSettings from "../components/ContextbarSettings.vue";
import { apiHelpers } from "../helpers";
import { uiHelpers } from "../ui_helpers";
import { useRoute } from "vue-router";
import { computed, onMounted, onUnmounted, provide} from "vue";
import { messages } from '../popups.js'
export default {
  name: "Settings",
  components: {
    ContextbarSettings,
    ModelDetails
  },
  setup() {
    const route = useRoute();
    const model = route.params.model;
    const { getrecorddetails, store } = apiHelpers(model);
    const { showerorr, notify } = messages()
    const { getsingletonmodelmetadata } = uiHelpers(model);
    onMounted(async () => {
      await getsingletonmodelmetadata();
    });
    onUnmounted(async () => {
      store.commit("api/reset");
      store.commit("ui/reset");
    });

    const keys = computed(() => {
      return 1
    });

    const save_record = () => {
      store
        .dispatch("api/post_put", {
          model: model
        })
        .then(() => {
          notify('success', "Saved");
        })
        .catch((err) => {
          showerorr(err);
        });
    };
    const cancelChanges = async () => {
        await getrecorddetails(1);
    };

    const runFunction = (method) => {
      if (method == "save_record") save_record()
      if (method == "cancelChanges") cancelChanges()
    };

    provide("runFunction", runFunction)
    return {
      keys,
      route,
      model
    };
  },
};
</script>


<style scoped>
#content {
  margin: 0 !important;
}
.el-row {
  margin-bottom: 0px !important;
}
.details_style {
  border-left: 1px solid rgb(192, 193, 197);
  border-right: 1px solid rgb(192, 193, 197);
  margin: 0 auto;
}
</style>
