<template>
  <el-scrollbar style="height: calc(100vh - 60px); width: 100%;">

    <el-row>
      <el-col
        :span="24 / home.length"
        v-for="(col, index_c) in home"
        :key="'c' + index_c"
        :class="colclass"
        style="padding:7px;"
      >
        <el-row v-for="(row, index_r) in col" :key="'r' + index_r">
          <el-col>
            <div class="widget">
              <div v-if="edit">
                <el-input placeholder="Title" v-model="row.title" />
              </div>
              <div v-else>
                <h5>{{ row.title }}</h5>
              </div>
              <div v-if="row.type == 'list'">
                <ListWidget :module="row.module" :view="row.view" />
              </div>
              <div v-if="row.type == 'customlist'">
                <CustomListWidget :url="row.url" />
              </div>
              <div v-if="row.type == 'legendlist'">
                <LegendListWidget />
              </div>
              <div v-else-if="row.type == 'linkset'">
                <LinkSetWidget :links="row.links" />
                <div v-if="edit">
                  <el-button type @click="addLink(index_c, index_r)">{{ $t('misc.add_l') }}</el-button>
                </div>
              </div>
              <div v-else>
                <div v-if="edit">
                  <el-button type @click="addTable(index_c, index_r)">{{ $t('misc.data') }}</el-button>
                  <el-button
                    type
                    @click="addCustomTable(index_c, index_r)"
                  >{{ $t('misc.custom_d') }}</el-button>
                  <el-button
                    type
                    @click="addLegendTable(index_c, index_r)"
                  >{{ $t('misc.legend_d') }}</el-button>
                  <el-button
                    type
                    @click="addNavigation(index_c, index_r)"
                  >{{ $t('misc.navigation') }}</el-button>
                </div>
              </div>
            </div>

            <div v-if="edit">
              <el-button type @click="removeRow(index_c, index_r)">{{ $t('misc.remove_w') }}</el-button>
            </div>
          </el-col>
        </el-row>

        <div v-if="edit">
          <el-button type @click="addRow(index_c)">{{ $t('misc.add_w') }}</el-button>
          <el-button type @click="removecol(index_c)">{{ $t('misc.remove_c') }}</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-bottom:10px;">
      <el-col>
        <div style="float: right;margin-right:10px;">
          {{ $t('misc.edit') }}
          <el-switch v-model="edit" />
        </div>
      </el-col>
    </el-row>
    <div v-if="edit">
      <div v-if="edit && home.length < 4">
        <el-button type @click="addCol">{{ $t('misc.add_c') }}</el-button>
      </div>
      <el-button type @click="saveLayout">{{ $t('misc.save') }}</el-button>
    </div>
  </el-scrollbar>
</template>


<script setup>
import ListWidget from '@/components/widgets/ListWidget'
import CustomListWidget from '@/components/widgets/CustomListWidget'
import LinkSetWidget from '@/components/widgets/LinkSetWidget'
import LegendListWidget from '@/components/widgets/LegendListWidget'
 
import { messages } from '../popups.js'
import { ElMessageBox } from 'element-plus'
import { ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";
const store = useStore();
let edit = ref(false)
let home = ref([])
const { showerorr } = messages()
const getHome = async () => {
  store.$axios.get("/development/dashboard/getLayout", {}).
    then(response => {
      home.value = response.data
    }).
    catch(err => showerorr(err))
}

const addLink = (indexc, indexr) => {
  ElMessageBox.prompt('Choose function', '', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
  })
    .then(({ value }) => {
      home.value[indexc][indexr].links.push({ text: value, url: "/" + value })
    })

}

const addNavigation = (indexc, indexr) => {
  home.value[indexc][indexr] = {
    type: 'linkset',
    links: []
  }

}

const addTable = (indexc, indexr) => {
  ElMessageBox.prompt('Choose view', '', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
  })
    .then(({ value }) => {
      store.$axios.post("/development/view/getlistmeta/", { view: value }).
        then(response => {
          // console.log(response)
          home.value[indexc][indexr] = { type: 'list', module: response.data.module, view: response.data.view }
        })

    })
}

const addCustomTable = (indexc, indexr) => {
  ElMessageBox.prompt('URL', '', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
  })
    .then(({ value }) => {
      home.value[indexc][indexr] = { type: 'customlist', url: value }

    })
}


const addLegendTable = (indexc, indexr) => {
    home.value[indexc][indexr] = { type: 'legendlist' }
}

const removecol = (indexc) => {
  home.value.splice(indexc, 1)
}
const addCol = () => {
  home.value.push([])
}
const removeRow = (indexc, indexr) => {
  home.value[indexc].splice(indexr, 1)
}
const addRow = (index_c) => {
  home.value[index_c].push({})
}
const saveLayout = () => {
  store.$axios.post("/development/dashboard/saveLayout/", home.value).
    then(() => {
      // console.log(response)
      edit.value = false
    })
}

const colclass = computed(() => edit.value == true ? "bordered" : "")
onMounted(async () => {
  await getHome()
})
</script>


<style>
.widget h5 {
  margin: 10px 10px 10px 10px;
}
.widget {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.4);
  border-top: 5px solid #5f7fa9;
}
.widget .el-table {
  border-radius: 3px;
}
.bordered {
  border: 1px solid rgb(219, 219, 219);
}
</style>