import router from '@/routes.js';
const FileDownload = require('js-file-download');

const api = {
    namespaced: true,
    state() {
        return {
            root_url: "",
            model: "",
            list: [],
            list_count: 0,
            list_next: null,
            list_previous: null,
            filelist: [],
            status: "",
            keys: [],
            record_details: {},
            history: null,
            schedule_details: {},
            dialog_details: {},
            dialog_details_initiated: false,
            view: {},
            view_dialog: {},
            err: null,
            lookup_data: [],
            lookup_list_count: 0,
            log_visible: false,
            log:[],
            freeze: false,
            is_singleton : null,
            something_runing : false,
            progress: 0
        }
    },
    mutations: {
        freeze(state,) {
            state.freeze = true 
        },
        unfreeze(state,) {
            state.freeze = false 
        },
        something_runing_begin(state){
            state.something_runing = true
        },
        something_runing_end(state){
            state.something_runing = false
        },
        show_log(state, payload) {
            if (payload.data !== undefined){
                state.log_visible = true
                state.log = payload.data
            }
            
        },
        set_progress(state, payload) {
            if (payload.data !== undefined){
                state.log_visible = true
                state.progress = payload.data
            }
            
        },
        close_log(state) {
            state.log_visible = false
            state.progress = 0
            state.log = []
        },
        report(state, payload) {
            state.record_details.data = payload.data.data
        },
        list(state, payload) {
            state.list = payload.data.results
            state.list_count = payload.data.count
            state.list_next = payload.data.next
            state.list_previous = payload.data.previous
        },
        clear_dialog(state) {
            state.dialog_details = {}
            state.view_dialog = {}
            state.dialog_details_initiated = false
        },
        history(state, payload) {
            state.history = payload.data
            console.log(state.history)
        },
        details(state, payload) {
            if (payload.data !== undefined){
                state.record_details = payload.data
            }
            
            if (state.status == "D" &&  payload.status == "M")
                state.status = "D"
            else if (state.status == "C" &&  payload.status == "M")
                state.status = "C"
            else
                state.status = payload.status
        },
        schedule_cancel(state) {
            state.schedule_details = {}
        },
        schedule_details(state,payload) {
            state.schedule_details = Object.assign({},payload.datamodel);
            state.schedule_details['target'] = state.root_url
            for (let r in this.state.ui.view.fields) {
                for (let c in this.state.ui.view.fields[r]) {
                    if (this.state.ui.view.fields[r][c].f > '')
                    state.schedule_details['params_json'].push({
                        field: this.state.ui.view.fields[r][c].f,
                        type: this.state.ui.view.fields[r][c].t
                    })
                }
            }
            
        },
        meta(state,payload) {
            state.record_details = Object.assign({},payload.datamodel);
            state.root_url = payload.root_url
            state.keys = payload.keys
            state.model = payload.model
            state.is_singleton = payload.is_singleton
        },
        meta_dialog(state,payload) {
            state.dialog_details = Object.assign({},payload.datamodel);
            state.dialog_details_initiated = true

        },
        filelist(state,payload){
            state.filelist = payload.data.results
        },
        deletefile(state,payload){
            state.filelist = state.filelist.filter(file=>file.id !=payload.id );
        },
        addfile(state,payload){
            state.filelist.push(payload.data)
        },
        newRecord(state) {
            for (var key in this.state.ui.record_model) {
                if (this.state.ui.record_model[key] == null){
                    state.record_details[key] = ""
                }else{
                    state.record_details[key] = this.state.ui.record_model[key]
                }
                
            }
            state.status = "C"
            state.filelist = []
        },
        noRecord(state) {
            this.commit("api/newRecord")
            state.status = ""
        },
        status(state, payload) {
            state.status = payload
        },
        setState(state,payload) {
            if(state.status == "R"){
                if (payload.grid == null & state.keys.indexOf(payload.field) > -1)
                    state.status = "D"
                else
                    state.status = "M"
            } else if(state.status == "")
                state.status = "C"
        },
        fieldchange(state, payload) {
            if (payload.grid){
                // console.log(payload)
                state[payload.view][payload.grid][payload.row][payload.field] = payload.value
            } else{
                state[payload.view][payload.field] = payload.value
            }
            
        },
        delete_gridline(state, payload) {
            state[payload.view][payload.grid].splice(payload.row, 1)
            if(state.status == "R")
                state.status = "M"
        },
        error(state, err) {
            state.err = err
        },
        lookup_list(state, payload) {
            state.lookup_data = payload.data.results
            state.lookup_list_count = payload.data.count
        },
        modules(state, payload) {
            state.modules = payload.data
        },
        functions(state, payload) {
            state.functions = payload.data
        },
        add_row(state, payload) {
            if (typeof(state[payload.vmodel][payload.grid])== 'undefined'){
                state[payload.vmodel][payload.grid] = []
            }
            state[payload.vmodel][payload.grid].push({})

            
        },
        reset(state){
            state.root_url= "";
            state.model= "";
            state.list= [];
            state.list_count= 0;
            state.list_next= null;
            state.list_previous= null;
            state.filelist=[];
            state.status= "";
            state.keys=[];
            state.record_details= {};
            state.dialog_details={};
            state.view= {};
            state.view_dialog= {};
            state.err= null;
            state.lookup_data= [];
        },
        after_edit(state,payload) {
            for (var key in payload.data) {
                state.record_details[key] = payload.data[key]
            }
                     
        },
    },
    getters: {
        key(state) {
            if (state.model !== undefined){
                return Array.from(state.keys, (x) => state.record_details[x]).join("~");
            }
            else{
                return null
            }
            
        },
        model_url(state,getters) {
            return (state.is_singleton) ? state.root_url : state.root_url + getters.key+ "/";
        },
        filelist(state) {
            return state.filelist
        },
        something_runing(state){
            return state.something_runing
        }
    },
    actions: {
        async get({ commit }, params) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => { 
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.get(params.url,{ params:params.q})
                    .then(response => {
                        commit(params.type,
                            {
                                data: response.data,
                                status: "R"
                            })
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },
        async post_put({ getters, state, commit }) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                if (state.status == "C" || state.status == "D"){
                    this.$axios.post(state.root_url, state.record_details)
                    .then(response => {
                        commit('details',
                            {
                                data: response.data,
                                status: ""
                            })
                        router.push({params: {key: getters.key}})
                        commit('show_log',{data: response.data.log, progress: false})
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
                } else{
                    this.$axios.put(getters.model_url, state.record_details)
                    .then(response => {
                        commit('details',
                            {
                                data: response.data,
                                status: "R"
                            })
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
                }
                
            })

        },
        async after_edit({ state, commit }, params) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                // if (this.state.auth.dologout == true){
                //     resolve()
                //     return
                // }
                // console.log(params)
                if (this.state.ui.is_singleton){
                    resolve()
                    if (params.view == 'record_details')
                        if (params.is_lookup){
                            commit("fieldchange", {
                                field: params.field,
                                grid: params.grid,
                                row: params.row,
                                value: params.value,
                                view: params.view
                              });
                        }
                        commit('setState',{field : params.field,grid: params.grid})
                    return
                }
                commit("fieldchange", {
                    field: params.field,
                    grid: params.grid,
                    row: params.row,
                    value: params.value,
                    view: params.view
                });
                this.$axios.post(state.root_url + "after_edit/", {field: params.field, grid: params.grid,row: params.row, value: params.value, record : state.record_details})
                .then(response => {
                    if (response.data != {}){
                        commit('after_edit',{data: response.data})
                    }
                    resolve(response)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
                .finally(() =>{
                    if (params.view == 'record_details')
                        commit('setState',{field : params.field,grid: params.grid})
                })
                
            })

        },
        async backend_method({state, commit },params) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.post(state.root_url + params.method + "/", {data :state.record_details, params: state.dialog_details})
                .then(response => {
                    commit('details',
                        {
                            data: response.data.data,
                            status:  params.status
                        })
                    commit('show_log',{data: response.data.log, progress: false})
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
                
            })

        },
        async run_function({state,commit }, schedule) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.post(state.root_url, {params :state.record_details, schedule:schedule, schedule_params :state.schedule_details})
                .then(response => {
                    commit('show_log',{data: response.data.log, progress: response.data.task_id != null})
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
                
            })

        },
        async run_export({state,commit }) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.post(state.root_url, {params :state.record_details})
                .then(response => {
                    commit('show_log',{data: response.data.log, progress: response.data.task_id != null})
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
                
            })

        },
        async get_next_prev({state,getters,commit },params) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.get(getters.model_url + params.method + "/")
                .then(response => {
                    commit('details',
                        {
                            data: response.data,
                            status:  "R"
                        })
                    this.dispatch("api/get", { url: "/common/media/", type: 'filelist', q:{model:state.model,code:getters.key} })
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
                
            })

        },

        async delete({getters}) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.delete(getters.model_url)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },
        async fileUpload({getters, state, commit},file) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                    let formData = new FormData()
                    formData.append("docfile", file.file)
                    formData.append("modelcode", state.model)
                    formData.append("code", getters.key)
                    this.$axios.post("common/media/",formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        commit('addfile',
                            {
                                data: response.data
                            })
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
                
            })

        },
        async filedelete({commit}, params) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                this.$axios.delete("common/media/" + params.key + "/")
                    .then(response => {
                        commit('deletefile',
                            {
                                id: params.key
                            })
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },
        async filedownload({commit}, params) {
            let waited = 0;
            while (
                this.state.freeze == true &&
                waited < 5000
            ) {
                await new Promise((r) => setTimeout(r, 100));
                waited += 100
            }
            return new Promise((resolve, reject) => {
                if (this.state.auth.dologout == true){
                    resolve()
                }
                
                const url = params.url
                const display = params.display
                
                commit('something_runing_begin')
                this.$axios.get(url,{responseType: 'blob', params:params.q})
                .then((data) => {
                    // console.log(data)
                    if (display == true){
                        commit('something_runing_end')
                        resolve(data.data)
                    } else{
                        const filename = data.headers["content-disposition"].split('"')[1];
                        FileDownload(data.data,filename)
                        commit('something_runing_end')
                        resolve(null)
                    }
                    
                  })
                    .catch(async (err) => {
                        err.response["data"] = JSON.parse(await err.response.data.text())
                        commit('something_runing_end')
                        reject(err)
                    })
            })

        },
    }
}

export default api;