<template>
    <el-dialog v-if="!store.state.auth.read_only" v-model="dialogVisible" title="Tips" width="50%" @closed="handleClosed">
        <ModelDetails :model="f" type="F" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="run_export">OK</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { uiHelpers } from "../ui_helpers";
import { useStore } from 'vuex'
import ModelDetails from "../components/ModelDetails.vue";

import { messages } from '../popups.js'
const route = useRoute()
const router = useRouter()
const store = useStore()
const f = route.params.function
const { getviewmetadata } = uiHelpers(f);
const { showerorr, notify } = messages()
const dialogVisible = ref(false)
onMounted(async () => {
    await getviewmetadata(f);

    dialogVisible.value = true
})

onUnmounted(async () => {
    store.commit("api/reset");
    store.commit("ui/reset");
});

const handleClosed = () => {
    router.go(-1)
}

const run_export = () => {
    store.commit('api/something_runing_begin')
    store
        .dispatch("api/run_export")
        .then(() => {
            notify('success', "Success");
        })
        .catch((err) => {
            store.commit('something_runing_end')
            showerorr(err)
        });
}

</script>
    
<style>
</style>
