<template>
  <table>
    <tr :key="week" v-for="week in weeks_in_month">
      <td :key="day" v-for="day in getDaysInWeek(week)">{{(week-1)*7 + day}}</td>
    </tr>
  </table>
</template>


<script setup>
// import { apiHelpers } from "../helpers";
// const { store } = apiHelpers(props);
import { ref } from 'vue';
const date = new Date();
let currentYear = ref(date.getFullYear());
let currentMonth = ref(0)


const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
}
const getFirstDayOfMonth = (year, month) => {
  console.log(year, month)
  return new Date(year, month, 1).getDay()+1;
}
const daysInCurrentMonth = getDaysInMonth(currentYear.value, currentMonth.value);
const firstWeekDay = getFirstDayOfMonth(currentYear.value, currentMonth.value);

console.log("firstWeekDay",firstWeekDay)
let weeks_in_month = ref(Math.ceil(daysInCurrentMonth/7))
console.log(daysInCurrentMonth)

const getDaysInWeek = (week) => {
  console.log("week",week)
  if (week == weeks_in_month.value){
    return 7-(week*7 - daysInCurrentMonth)
  } else if (week==1) {
    return firstWeekDay
  }else{
    return 7
  }
}

</script>

<style scoped>
  td {
    padding: 50px 70px;
    border: 1px solid grey;
  }
</style>
