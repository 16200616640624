import { createI18n } from "vue3-i18n";
const messages = {
    en: {
      message: {
        hello: 'Hello'
      },
      common: {
        yes: 'Yes',
        no: 'No'
      },
      contextbar:{
        next: "Next",
        prev: "Previos",
        actions: "Actions",
        files: "Attachements",
        save: "Save",
        cancel: "Cancel",
        delete: "Delete",
        new: "New",
        ok: "OK",
        comfirm_delete: "Delete record?",
        random: "Delete record?",
        history: "History"
      },
      misc:{
        data: "Data",
        settings: "Settings",
        functions: "Functions",
        reports: "Reports",
        exports: "Exports",
        imports: "Imports",
        edit: "Edit",
        remove_w: "Remove widget",
        add_w: "Add widget",
        remove_c: "Remove column",
        add_l: "Add link",
        add_c: "Add column",
        save: "Save",
        custom_d: "Custom data",
        legend_d: "Legend table",
        navigation: "Navigations",
        line: "Line"

      },
      navbar:{
        logout: "Logout",
        language: "Language"
      },
      messages:{
        con_restored: "Server connection restored",
        con_lost: "Server connection lost",
        broadcast: "Broadcast message",
        ses_end_in: "Session will end in ",
        ses_sec: " seconds",
        ses_min: " minutes",
        ses_ended: "Session ended",
        unsaved_leave: "Do you really want to leave? you have unsaved changes!",
        lose_context: 'Running process! Reloading will lose context. Continue?'
      }
    },
    lv: {
      message: {
        hello: 'Sveiki'
      },
      common: {
        yes: 'Jā',
        no: 'Nē'
      },
      contextbar:{
        next: "Nāk.",
        prev: "Iepr.",
        actions: "Darbības",
        files: "Pielikumi",
        save: "Saglabāt",
        cancel: "Atcelt",
        delete: "Dzēst",
        new: "Jauns",
        ok: "Labi",
        comfirm_delete: "Dzēst ierakstu?",
        history: "Vēsture"
      },
      misc:{
        data: "Dati",
        settings: "Iestatījumi",
        functions: "Funkcijas",
        reports: "Atskaites",
        exports: "Eksporti",
        imports: "Importi",
        edit: "Pielāgot",
        remove_w: "Dzēst rīku",
        add_w: "Pievienot rīku",
        remove_c: "Dzēs kolonu",
        add_l: "Pievienot saiti",
        add_c: "Pievienot kolonu",
        save: "Saglabāt",
        custom_d: "Pielāgoti dati",
        legend_d: "Leģendas tabula",
        navigation: "Navigācija",
        line: "Rinda"

      },
      navbar:{
        logout: "Iziet",
        language: "Valoda"
      },
      messages:{
        con_restored: "Savienojums ar serveri atjaunots",
        con_lost: "Savienojums ar serveri pārtrūcis",
        broadcast: "Ziņapraide",
        ses_end_in: "Sesija beigsies pēc ",
        ses_sec: " sekundēm",
        ses_min: " minūtēm",
        ses_ended: "Sesija beigusies",
        unsaved_leave: "Vai tiešām vēlies pamest lapu? Ir nesaglabātas izmaiņas!",
        lose_context: 'Darbība procesā! Tiks zaudēts konteksts. Vai pārtlādēt lapu?'
      }
    }
  }
  
  const  i18n = createI18n({
    fallbackLocale: 'en', // set fallback locale
    messages,
  })

export default i18n;
