import { computed } from 'vue'
import { useStore } from "vuex";
import { useRoute } from 'vue-router'
import { messages } from '@/popups'
import { apiHelpers } from '@/helpers'

export const uiHelpers = (model) => {
    const route = useRoute()
    const store = useStore();
    const meta = computed(() => store.state.ui);
    const view = computed(() => store.state.ui.view);
    const menus = computed(() => store.state.ui.menus);
    const view_dialog = computed(() => { return store.state.ui.view_dialog});
    const modules = computed(() => store.state.ui.modules);
    const l_data = computed(() => store.state.ui.functions.filter(f => f.type == 1 || f.type == 9));
    const l_settings = computed(() => store.state.ui.functions.filter(f => f.type == 3));
    const l_functions = computed(() => store.state.ui.functions.filter(f => f.type == 2));
    const l_reports = computed(() => store.state.ui.functions.filter(f => f.type == 6));
    const l_exports = computed(() => store.state.ui.functions.filter(f => f.type == 5));
    const l_imports = computed(() => store.state.ui.functions.filter(f => f.type == 4));
    const lookup_meta = computed(() => store.state.ui.lookup);
    const type = computed(() => store.state.ui.type || 1);
    const { getrecordlist,getrecorddetails,getlookuplist} = apiHelpers(model);//, getlookuplist, getrecorddetails }
    const {showerorr} = messages()

    const translation = (txt) => store.state.ui.txt[txt]
//---------------------------------------------------------------------
    const getmoduleslist = async () => {
        store
            .dispatch("ui/get", { url: "/development/module/", type: 'modules',q:{"tehnical": false} })
            .then(()=>{})
            .catch((err) => {
                console.log(err)
                showerorr(err);
            });
    }

    const getfunctionslist = async (module) => {
        store
            .dispatch("ui/get", { url: "/development/" + model + "/" , model: model, type: 'functions', q:{ui_module:module} })
            .then(()=>{})
            .catch((err) => {
                console.log(err)
                showerorr(err);
            });
    }

    const getlookupmeta =  async(model) => {
        store
            .dispatch("ui/get", { url: "/development/meta/" + model + "/", model: model, type: 'lookup_meta' })
            .then(async ()=>{
                await getlookuplist(model,null);
            })
            .catch(async (err) => {
                console.log(err)
                showerorr(err);
            });
    }

    const getmodelmetadata = async (key) => {
        store.dispatch("ui/get", { url: "/development/meta/" + model, model: model, type: 'meta' })
            .then(async () => {
                await getrecordlist(1,{})
                if (key !== null && key !== undefined) {
                    await getrecorddetails(key)
                }
                
            })
            .catch((err) => {
                console.log(err)
                showerorr(err);
            });
    }


    const getmiscmodelmetadata = async () => {
        store.dispatch("ui/get", { url: "/development/meta/" + model, model: model, type: 'meta' })
            .then(async () => {
                await getrecorddetails()
            })
            .catch((err) => {
                showerorr(err);
            });
    }

    const getsingletonmodelmetadata = async () => {
        store.dispatch("ui/get", { url: "/development/meta/" + model, model: model, type: 'meta' })
            .then(async () => {
                await getrecorddetails(1)
            })
            .catch((err) => {
                showerorr(err);
            });
    }
        
    const getviewmetadata = async () => {
        store.dispatch("ui/get", { url: "/development/meta/" + model, model: model, type: 'meta' })
            .then(async () => {
            })
            .catch((err) => {
                console.log(err)
                showerorr(err);
            });
    }

    const getdialogmetadata =async (model) => {
        store
            .dispatch("ui/get", { url: "/development/meta/" + model, model: model, type: 'meta_dialog' })
            .then(() => {
            })
            .catch((err) => {
                showerorr(err);
            });
    }
    return {
        getmodelmetadata,
        getsingletonmodelmetadata,
        getmiscmodelmetadata,
        getviewmetadata,
        store,
        route,
        meta,
        view,
        getlookupmeta,
        getmoduleslist,
        getfunctionslist,
        modules,
        l_data,
        l_settings,
        l_functions,
        l_reports,
        l_exports,
        l_imports,
        getdialogmetadata,
        view_dialog,
        lookup_meta,
        translation,
        type,
        menus
    }
};
