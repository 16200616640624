<template>
    <el-dialog v-if="!store.state.auth.read_only" v-model="dialogVisible" title="Tips" width="50%" @closed="handleClosed">
        <ModelDetails :model="f" type="F" />
        <div v-if="schedule_info">
            <el-divider />
            <ModelDetails :model="f" type="SC" :override_ui="schedule_view" />
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="schedule_task">{{schedule_button_text}}</el-button>
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="run_function">OK</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { uiHelpers } from "../ui_helpers";
import { useStore } from 'vuex'
import ModelDetails from "../components/ModelDetails.vue";

import { messages } from '../popups.js'
const route = useRoute()
const router = useRouter()
const store = useStore()
const f = route.params.function
const { getviewmetadata } = uiHelpers(f);
const { showerorr, notify } = messages()
const schedule_info = ref(false)
const dialogVisible = ref(false)
const schedule_view = ref({})
onMounted(async () => {
    await getviewmetadata(f);
    store.dispatch("ui/get", { url: "/development/meta/periodictask", model: "periodictask", type: null })
    .then((res) => {
        schedule_view.value=res
        console.log(res)
        store.commit('api/schedule_details',res)
        store.commit('ui/add_menus',res.menus)
    })
    .catch((err) => {
        showerorr(err);
    });

    dialogVisible.value = true
})

onUnmounted(async () => {
    store.commit("api/reset");
    store.commit("ui/reset");
});

const handleClosed = () => {
    router.go(-1)
}
const schedule_task = () => {
    schedule_info.value = !schedule_info.value
    if (!schedule_info.value){
        store.commit('api/schedule_details',schedule_view.value)
    }
}

const schedule_button_text = computed(() => schedule_info.value ? 'Disable schedule' : 'Schedule')

const run_function = () => {
    store
        .dispatch("api/run_function",schedule_info.value)
        .then(() => {
            notify('success', "Success");
        })
        .catch((err) => {
            showerorr(err)
        });
}

</script>
    
<style>
</style>
