<template>
  <div id="drawer_container">
  <el-drawer direction="rtl" v-model="lookupvisible" size="50%" @open="onOpen" :show-close="false" :withHeader="false">
    <el-pagination
      :pager-count="5"
      :page-size="pagesize"
      :total="total_records"
      @current-change="current_page"
      layout="prev, pager, next"
      :small="true"
      background
      style="margin-left: 10px;width: auto; float: right; margin-top:5px;"
    ></el-pagination>
    <div id="lookup_list_container">
    <el-table
      ref="lookupList"
      :height="calc_height"
      :data="lookup_data"
      size="mini"
      @row-click="dblclick"
      style="width: 100%"
    >
      <!-- <el-table-column v-for="col in lookup_meta.cols" :key="col" :prop="col" :label="col"></el-table-column> -->
      <el-table-column v-for="col in lookup_meta.cols" :key="col.f" :prop="col.f" show-overflow-tooltip>
        <template #header>
          <input
            class="list_header"
            v-model="search[col.f]"
            v-on:keyup.enter="filter_list"
            :placeholder="translation(col.t)"
          />
        </template>
        <template v-if="col.tp == 'BooleanField'" #default="scope">
          <img v-if="scope.row[col.f]" src="/static/media/checked.png" />
          <img v-else src="/static/media/unchecked.png" />
        </template>
      </el-table-column>
    </el-table>
  </div>
  </el-drawer>
</div>
</template>


<script>
import { ref, onMounted, onUnmounted, inject, computed } from "vue";
import { apiHelpers } from "../helpers";
import { uiHelpers } from "../ui_helpers";
import { messages } from '@/popups'
export default {
  setup() {

    const { getlookupmeta, lookup_meta } = uiHelpers();
    const { showerorr } = messages()
    const { lookup_data, getlookuplist, store } = apiHelpers();
    const select_model = inject("select_model");
    const current_detail_store = inject("current_detail_store");
    const currentfield = inject("currentfield");
    const currentrow = inject("currentrow");
    const currentgrid = inject("currentgrid");
    const lookupvisible = inject("lookupvisible");
    let calc_height = ref(window.innerHeight - 50);
    let resize_handler = (e) => {
      calc_height.value = e.target.innerHeight - 50;
    };
    onMounted(() => {
      window.addEventListener("resize", resize_handler);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", resize_handler);
    });

    const onOpen = async () => {
      await getlookupmeta(select_model.value);

    };
    const dblclick = (row) => {
      const keys = Array.from(store.state.ui.lookup.keys, (x) => row[x]);
      console.log({ grid: currentgrid.value, row: currentrow.value, field: currentfield.value, value: keys[0], view: current_detail_store.value })
      
      store.dispatch('api/after_edit', { grid: currentgrid.value, row: currentrow.value, field: currentfield.value, value: keys[0], view: current_detail_store.value,is_lookup:true })
        .then(() => { })
        .catch(err => {
          showerorr(err)
        })

      currentfield.value = keys;
      lookupvisible.value = false;
    };
    const pagesize = computed(() => 50)
    const translation = (txt) => lookup_meta.value.translations[txt]
    const total_records = computed(() => store.state.api.lookup_list_count)
    const current_page = async (current) => {
      getlookuplist(select_model.value, current,search.value)
    }
    const search = ref({})
    async function filter_list() {
      getlookuplist(select_model.value, 1, search.value)
    }
    return {
      calc_height,
      lookup_data,
      onOpen,
      dblclick,
      lookupvisible,
      lookup_meta,
      pagesize,
      total_records,
      current_page,
      translation,
      search,
      filter_list
    };
  },
};
</script>

<style scoped>
.el-table .cell {
  word-break: keep-all !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.list_header {
  height: 20px;
}

#lookup_list_container >>> .el-table th.el-table__cell>.cell {
  padding: 0;
  padding-left: 3px;
}

#lookup_list_container >>> .el-table th.el-table__cell>.cell input {
  border: none;
  border-right: 1px;
  padding-left: 10x;
}


.el-table .cell {
  word-break: keep-all !important;
}
.el-table >>> .warning-row {
  background-color: #ddccb2;
}
.el-table >>> .success-row {
  background-color: #acc59f;
}
.el-table >>> .error-row {
  background-color: #ffdcdc;
}
.el-table >>> .highlite-row {
  background-color: #daebfc;
}
.el-table >>> .extra_warning-row {
  background-color: #fd8181;
}
.el-table .current-row {
  font-weight: bold;
}

#lookup_list_container >>> .el-pagination.is-background .btn-next, 
#lookup_list_container >>> .el-pagination.is-background .btn-prev, 
#lookup_list_container >>> .el-pagination.is-background .el-pager li {
    margin: 0 3px !important;
}

#drawer_container >>> .el-drawer__body{
  padding: 5px 0 0 0 !important;
}
</style>
