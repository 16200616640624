<template>
  <el-table :data="table_data" size="mini" style="width: 100%">
    <el-table-column v-for="col in table_cols" :key="col" :prop="col" :label="col"></el-table-column>
  </el-table>
</template>


<script>
import { ref, onMounted } from 'vue'
import { useStore } from "vuex";
export default {
  props: {
    view: String,
    module: String
  },
  setup(props) {
    const store = useStore();
    let table_data = ref([])
    let table_cols = ref([])
    console.log("getData")
    const getCols = async () => {
      store.$axios.get("/development/meta/" + props.view).
        then(response => {
          table_cols.value = response.data.cols
        }).
        catch(err => console.log(err))
    }
    const getData = async () => {
      store.$axios.get("/" + props.module + "/" + props.view + "/").
        then(response => {
          table_data.value = response.data.results
        }).
        catch(err => console.log(err))
        setTimeout(getData,60000)
    }
    onMounted(async () => {
      await getCols()
      await getData()
    })

    return {
      table_data, table_cols
    };
  },
};
</script>

<style>
.el-table .cell {
  word-break: keep-all !important;
}
.el-table .warning-row {
  background-color: #ddccb2;
}
.el-table .success-row {
  background-color: #acc59f;
}
.el-table .error-row {
  background-color: #ffdcdc;
}
.el-table .highlite-row {
  background-color: #daebfc;
}
</style>
